import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class InventoryItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            included: false,
            quantity: this.props.quantity,
        }
    };

    handleChange(event) {
        //update quantity
        this.props.item.quantity = event.target.value;

        //include item on order if value > 0, else exclude
        if (event.target.value > 0 && this.state.included === false) {
            this.setState({
                included: true,
            });
            this.props.include(this.props.item);
        } else if (event.target.value <= 0) {
            this.setState({
                included: false,
            });
            this.props.exclude(this.props.item);
        }
        this.setState({
            quantity: event.target.value,
        });
    }

    editFromDB () {
        this.props.editFromDB(this.props.item);
    }

    deleteFromDB () {
        this.props.deleteFromDB(this.props.item);
    }

    render () {
        return (
            <tr>
                <td></td>
                <td>{this.props.item.name}</td>
                <td><input type="number" value={this.state.quantity} onChange={this.handleChange.bind(this)} /></td>
                {this.props.isAdmin ? <td><Button variant='warning' onClick={this.editFromDB.bind(this)}>Edit</Button></td> : false }
                {this.props.isAdmin ? <td><Button variant='danger' onClick={this.deleteFromDB.bind(this)}>Delete</Button></td> : false }
            </tr>
        )
    };
}
  
export default InventoryItem;