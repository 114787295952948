import React, { Component } from 'react';
import './reconciliation-page.styles.css';
import app from '../../firebase/firebase.utils';
import { AuthContext } from '../../Auth';
import Header from '../header/header.component';
import { Link } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import LoadingPage from '../loading-page/loading-page.component';
import ReconciliationContainer from './reconciliation-container/reconciliation-container.component';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

class ReconciliationPage extends Component {
    static contextType = AuthContext;

    constructor() {
        super();
        var currentDate = new Date();
        this.state = {
            date: currentDate,
            loaded: false,
            gas: [],
            inputs: [],
            reconciliationList: {},
            isAdmin: false,
            users: [],
        }
    };

    async componentDidMount() {
        if (this.props.location.state.displayName === 'Admin') {
            this.setState({
                isAdmin: true,
            })
            var firstUser = null;
            await app.database().ref('users').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var users = Object.keys(snapshot.val());
                    for (var i = 0; i < users.length; i++) {
                        if (snapshot.val()[users[i]].name !== 'Admin') {
                            if (i === 1) {
                                firstUser = snapshot.val()[users[i]].name;
                                this.setState({
                                    activeUser: firstUser
                                })
                            }
                            this.state.users.push(snapshot.val()[users[i]])
                        }
                        
                    }
                }
            });
            await app.database().ref('reconciliationData/' + this.state.activeUser + '/gasData/gas').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var gas = Object.keys(snapshot.val());
                    for (var i = 0; i < gas.length; i++) {
                        this.state.gas.push(snapshot.val()[gas[i]])
                    }
                }
            });
        } else {
            await app.database().ref('reconciliationData/' + this.props.location.state.displayName + '/gasData/gas').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var gas = Object.keys(snapshot.val());
                    for (var i = 0; i < gas.length; i++) {
                        this.state.gas.push(snapshot.val()[gas[i]])
                    }
                }
            });
        }

        if (this.props.location.state.reconciliationList !== undefined) {
            this.setState({
                reconciliationList: this.props.location.state.reconciliationList
            })
        } else {
            await app.database().ref('reconciliationData/general/gasData/gasTableInputs').once('value', snapshot => {
                var k = 0;
                if (snapshot.val() !== null) {
                    var tables = Object.keys(snapshot.val());
                    for (var i = 0; i < tables.length; i++) {
                        var inputs = Object.keys(snapshot.val()[tables[i]]);
                        for (var j = 0; j < inputs.length; j++) {
                            const reconciliationList = this.state.reconciliationList;
                            if (j === 0) {
                                reconciliationList[snapshot.val()[tables[i]][inputs[j]]] = {}
                                var heading = snapshot.val()[tables[i]][inputs[j]];
                            } else if (j === 1) {
                                reconciliationList[heading]['info'] = snapshot.val()[tables[i]][inputs[j]];
                            } else {
                                reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]] = []
                                if (heading !== 'Inventory') {
                                    for (k = 0; k < this.state.gas.length; k++) {
                                        reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]].push({
                                            name: this.state.gas[k],
                                            quantity: 0
                                        })
                                    }
                                } else {
                                    for (k = 0; k < this.state.gas.length-1; k++) {
                                        reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]].push({
                                            name: this.state.gas[k],
                                            quantity: 0
                                        })
                                    }
                                }
                            }
                            this.setState(reconciliationList);
                        }
                    }
                }
            });
        }

        this.setState({
            loaded: true
        })
    }

    handleDateChange = (date) => {
        this.setState({
            date: date
        });
      }

      handleUserChange = (user) => {
        this.setState({
            activeUser: user
        });
      }

    handleChange = (heading, subheading, item, quantity) => {
        for (var i = 0; i < this.state.reconciliationList[heading][subheading].length; i++) {
            if (this.state.reconciliationList[heading][subheading][i].name === item.name){
                const reconciliationList = this.state.reconciliationList;
                reconciliationList[heading][subheading][i].quantity = parseFloat(quantity);
                this.setState(reconciliationList);
                break;
            }
        }
    }

    content () {
        return (
            <div className='reconciliation-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                {this.state.submitSpinner? <div>Loading...</div> :
                <div className='container container-with-footer'>
                    <div>
                    {this.state.isAdmin? 
                    <Form.Group>
                    <Form.Label>Reconciliation for:  </Form.Label>
                        <Form.Control as="select" onChange={this.handleUserChange.bind(this)}>
                            {this.state.users.map(function(user, userIdx, ) {
                                return (
                                    <option key={userIdx}>{user.name}</option>
                            )})}
                        </Form.Control>    
                    </Form.Group>
                    : "Reconciliation for: " + this.props.location.state.displayName}
                    </div>

                    <div>
                    {this.state.isAdmin?
                       <span><span>Date: </span>
                         <DatePicker selected={this.state.date} onChange={this.handleDateChange.bind(this)}/>
                         </span>
                        : <span>Date: {(this.state.date.getMonth() + 1) + "-" + this.state.date.getDate() + "-" + this.state.date.getFullYear()}</span>
                    
                    }
                    </div>
                    <div className='row'>
                        {Object.keys(this.state.reconciliationList).map(function(inputGroup, inpGrpIdx, ) {
                            return (
                                <React.Fragment key={inpGrpIdx}>
                                {Object.keys(this.state.reconciliationList[inputGroup]).map(function(inputItem, idx,) {
                                    if (idx < 2) {
                                        if (inputItem !== 'info') {
                                            return (
                                                <ReconciliationContainer key={idx} styling='firstColumn threeColumn' heading={inputGroup} info={this.state.reconciliationList[inputGroup].info} subheading={inputItem} data={this.state.reconciliationList[inputGroup][inputItem]} handleChange={this.handleChange}></ReconciliationContainer>
                                            )
                                        } else {
                                            return false
                                        }
                                    } else if (idx === 2) {
                                        if (inputItem !== 'info') {
                                            return (
                                                <React.Fragment key={idx}>
                                                    <h3 className='threeColumn'>Delivery</h3>
                                                    <ReconciliationContainer styling='threeColumn' heading={inputGroup} info={this.state.reconciliationList[inputGroup].info} subheading={inputItem} data={this.state.reconciliationList[inputGroup][inputItem]} handleChange={this.handleChange}></ReconciliationContainer>
                                                </React.Fragment>
                                            )
                                        } else {
                                            return false
                                        }
                                    } else if (idx === 3) {
                                        if (inputItem !== 'info') {
                                            return (
                                                <ReconciliationContainer key={idx} styling='threeColumn' heading={inputGroup} info="Please enter $ amount" subheading={inputItem} data={this.state.reconciliationList[inputGroup][inputItem]} handleChange={this.handleChange}></ReconciliationContainer>
                                            )
                                        } else {
                                            return false
                                        }
                                    } else {
                                        return false
                                    }
                                }, this)}
                                </React.Fragment>
                            )
                        }, this)}
                    </div>
                    <div className='sticky-footer'>
                    <Link to={{pathname: "/reconciliation/summary", state: { reconciliationList: this.state.reconciliationList, displayName: this.props.location.state.displayName, currentDate: this.state.date, activeUser: this.state.activeUser, }}}>
                                <Button className='float-right'>Submit</Button>
                    </Link>
                    </div>
                </div>
                }
            </div>
            
        )
    }

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}

export default ReconciliationPage;