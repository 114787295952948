import React, { Component } from 'react';
import '../../components.styles.css'
import app from '../../../firebase/firebase.utils';
import Header from '../../header/header.component';
import LoadingPage from '../../loading-page/loading-page.component';
import {Table, Button, Modal, Spinner} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

class PastReconciliationPage extends Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            pastOrders: {},
            editPage: false,

            showDeleteFileModal: false,
            deleteItem: {},
            deleteCompany: '',
            deleteFileSpinner: false,
        }
    };

    async componentDidMount(){
        var dispName = [];
        if (this.props.location.state.displayName === "Admin") {
            await app.database().ref('users/').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var keys = Object.keys(snapshot.val());
                    for (var i = 0; i < keys.length; i++) {
                        dispName.push(snapshot.val()[keys[i]].name)
                    }
                }
            });
        }

        await app.database().ref('files/').once('value', snapshot => {
            for (var i = 0; i < dispName.length; i++) {
                if (snapshot.val() !== null) {
                    if (snapshot.val()[dispName[i]] !== undefined) {
                        if (snapshot.val()[dispName[i]]["Reconciliation"] !== undefined) { 
                            var keys = Object.keys(snapshot.val()[dispName[i]]["Reconciliation"]);
                            for (var k = 0; k < keys.length; k++) {
                                if (this.state.pastOrders[dispName[i]] === undefined) {
                                    const pastOrders = this.state.pastOrders;
                                    pastOrders[dispName[i]] = {};
                                    pastOrders[dispName[i]]["Reconciliation"] = [snapshot.val()[dispName[i]]["Reconciliation"][keys[k]]];
                                    this.setState(pastOrders);
                                } else {
                                    const pastOrders = this.state.pastOrders;
                                    pastOrders[dispName[i]]["Reconciliation"].push(snapshot.val()[dispName[i]]["Reconciliation"][keys[k]]);
                                    this.setState(pastOrders);
                                }
                            }
                        }
                    }
                }
            }
        });
        this.setState({ 
            loaded: true,
        });
    }

    showDeleteFileModal () {
        this.setState({
          showDeleteFileModal: !this.state.showDeleteFileModal
        });
    }

    showInitialDeleteFileModal (item, company) {
        this.setState({
          showDeleteFileModal: !this.state.showDeleteFileModal,
          deleteItem: item,
          deleteCompany: company
        });
        console.log(company)
    }

    async deleteFile (){
        this.setState({
            deleteFileSpinner: true
        });

        var date = this.state.deleteItem.dateCreated.split("-");
        //reverse last calculation
        var pastReconciliation = null;
        await app.database().ref('reconciliation/pastReconciliation/' + date[2] + "/" + date[0] + "/" + date[1] + "/" + this.state.deleteItem.name).once('value', snapshot => {
            if (snapshot.val() !== null) {
                pastReconciliation = snapshot.val();
            }
        });
        var reconciliationDataLast = null;
        await app.database().ref('reconciliationData/' + this.state.deleteCompany + "/gasData/last").once('value', snapshot => {
            if (snapshot.val() !== null) {
                reconciliationDataLast = snapshot.val();
            }
        });

        var tables = Object.keys(reconciliationDataLast);
        for (var i = 0; i < tables.length; i++) {
            var gasTypes = Object.keys(reconciliationDataLast[tables[i]]);
            for (var j = 0; j < gasTypes.length; j++){
                await app.database().ref('reconciliationData/' + this.state.deleteCompany + "/gasData/last/" + tables[i] + "/" + gasTypes[j]).set(reconciliationDataLast[tables[i]][gasTypes[j]] - pastReconciliation[tables[i]]['Ending'][gasTypes[j]]);
            }
        }
        
        //remove from DB
        await app.database().ref('files/' + this.state.deleteCompany + '/' + this.state.deleteItem.category + "/" + this.state.deleteItem.name).remove();
        await app.database().ref('reconciliation/pastReconciliation/' + date[2] + "/" + date[0] + "/" + date[1] + "/" + this.state.deleteItem.name).remove();

        //remove from storage
        var deleteRef = app.storage().ref().child('/' + this.state.deleteCompany + '/' + this.state.deleteItem.category + "/" + date[2] + "/" + date[0] + "/" + date[1] + "/" + this.state.deleteItem.name);
        await deleteRef.delete().then(function() {
            // File deleted successfully
            }).catch(function(error) {
            // Uh-oh, an error occurred!
        });

        //remove from local
        const pastOrders = this.state.pastOrders;
        var a = this.state.deleteItem.name;
        pastOrders[this.state.deleteCompany][this.state.deleteItem.category] = this.state.pastOrders[this.state.deleteCompany][this.state.deleteItem.category].filter(function(file) {
            return file.name !== a;
        });
        if (pastOrders[this.state.deleteCompany][this.state.deleteItem.category].length === 0) {
            delete pastOrders[this.state.deleteCompany]
        }
        this.setState(pastOrders);
        
        this.setState({
            deleteFileSpinner: false,
            showDeleteFileModal: false
        });
    }

    handleOrder (item, company) {
        var a = !item.ordered;

        var updates = {};
        updates['files/' + company + "/Reconciliation/" + item.name + "/ordered"] = a;
        app.database().ref().update(updates);
        
        const pastOrders = this.state.pastOrders;
        let idx = pastOrders[company][item.category].findIndex((obj => obj.name === item.name));
        pastOrders[company][item.category][idx].ordered = a;
        this.setState(pastOrders)
    }

    async handleEdit (item, company) {
        var date = item.dateCreated.split("-");
        //reverse last calculation
        var pastReconciliation = null;
        await app.database().ref('reconciliation/pastReconciliation/' + date[2] + "/" + date[0] + "/" + date[1] + "/" + item.name).once('value', snapshot => {
            if (snapshot.val() !== null) {
                pastReconciliation = snapshot.val();
            }
        });
        var reconciliationDataLast = null;
        await app.database().ref('reconciliationData/' + company + "/gasData/last").once('value', snapshot => {
            if (snapshot.val() !== null) {
                reconciliationDataLast = snapshot.val();
            }
        });

        this.setState({
            name: item.name,
            pastReconciliation: pastReconciliation,
            reconciliationDataLast: reconciliationDataLast,
            date: date,
            editPage: true,
        })
    }
    
    content () {
        if (this.state.editPage) {
            return (
                <Redirect to={{pathname: "/reconciliation/edit", state: { displayName: this.props.location.state.displayName, reconciliationDataLast: this.state.reconciliationDataLast, pastReconciliation: this.state.pastReconciliation, store: this.state.pastReconciliation.store, date: this.state.date, name: this.state.name }}} />
            )
        }
        return (
            <div className='past-order-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container'>
                    <Modal show={this.state.showDeleteFileModal} onHide={this.showDeleteFileModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete File</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete {this.state.deleteItem.name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showDeleteFileModal.bind(this)}>Cancel</Button>
                            {this.state.deleteFileSpinner? 
                            <Button variant="danger"><Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button variant="danger" onClick={this.deleteFile.bind(this)}>Delete File</Button>}
                        </Modal.Footer>
                    </Modal>

                    <h4 className='sticky-table-title-past-orders past-order-title'>Past Reconciliations</h4>
                    <Table hover size='sm'>
                        <thead>
                            <tr>
                                <th className='sticky-table-header-past-orders'>Company</th>
                                <th className='sticky-table-header-past-orders'>Reconciliation ID</th>
                                <th className='sticky-table-header-past-orders'>Date Created</th>
                                <th className='sticky-table-header-past-orders'>Edit?</th>
                                <th className='sticky-table-header-past-orders'>Approved?</th>
                                <th className='sticky-table-header-past-orders'>Delete?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.pastOrders).map(company => (
                                Object.keys(this.state.pastOrders[company]).map(file => (
                                    <React.Fragment key={file}>
                                        <tr>
                                            <td className='sticky-category-past-orders'>{company}</td> 
                                        </tr>
                                        {this.state.pastOrders[company][file].map(item => (
                                            <tr key={item.name}>
                                                <td></td>
                                                <td><a href={item.downloadURL} target="_blank" rel="noopener noreferrer">{item.name}</a></td>
                                                <td>{item.dateCreated}</td>
                                                {!item.ordered? <td><Button variant='warning' onClick={this.handleEdit.bind(this, item, company)}>Edit</Button></td> : <td>APPROVED</td>}
                                                {item.ordered?<td><input type="checkbox" id="ordered" name="ordered" onChange={this.handleOrder.bind(this, item, company)} checked></input></td> : <td><input type="checkbox" id="ordered" name="ordered" onChange={this.handleOrder.bind(this, item, company)}></input></td>}
                                                {!item.ordered? <td><Button variant='danger' onClick={this.showInitialDeleteFileModal.bind(this, item, company)}>Delete</Button></td> : <td>APPROVED</td>}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() 
                :  <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}
  
export default PastReconciliationPage;