import React, { Component } from 'react';
import app from '../../../firebase/firebase.utils';
import 'jspdf-autotable';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../../header/header.component';
import LoadingPage from '../../loading-page/loading-page.component';
import '../../components.styles.css';


class ReconciliationMonthlySummaryPage extends Component {
    constructor(props) {
        super(props);

        var currentDate = this.props.location.state.currentDate;
        this.state = {
            currentDate: currentDate,
            reconciliation: [],
            gas: [],
            loaded: false,
            verificationSpinner: false,
            submitted: false,
            dispName: null,
            isAdmin: false,
            startValues: {}
        }
    };

    async componentDidMount () {
        this.setState({
                isAdmin: true,
        })
        await app.database().ref('reconciliation/pastReconciliation/' + this.props.location.state.selectedYear + "/" +  this.props.location.state.selectedMonth + "/monthlyData/" + this.props.location.state.selectedUser + "/").once('value', snapshot => {
            if (snapshot.val() !== null) {
                console.log(snapshot.val())
            } else {
                console.log('no schedule')
            }
        });
        /*

        await app.database().ref('reconciliationData/' + dispName + '/gasData/gas').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var gas = Object.keys(snapshot.val());
                for (var i = 0; i < gas.length; i++) {
                    this.state.gas.push(snapshot.val()[gas[i]]);
                }
            }
        });

        await app.database().ref('reconciliationData/general/gasData/gasTables').once('value', snapshot => {
            var k = 0;
            if (snapshot.val() !== null) {
                var tables = Object.keys(snapshot.val());
                for (var i = 0; i < tables.length; i++) {
                    var tableItem = Object.keys(snapshot.val()[tables[i]]);
                    for (var j = 0; j < tableItem.length; j++) {
                        const reconciliation = this.state.reconciliation;
                        if (j === 0) {
                            var heading = snapshot.val()[tables[i]][tableItem[j]];
                            reconciliation.push([]);
                            reconciliation[i].push([heading]);
                            if (heading === 'Inventory') {
                                for (k = 0; k < this.state.gas.length-1; k ++) {
                                    reconciliation[i][j].push(this.state.gas[k])
                                }
                            } else {
                                for (k = 0; k < this.state.gas.length; k ++) {
                                    reconciliation[i][j].push(this.state.gas[k])
                                }
                            }
                        } else {
                            reconciliation[i].push([snapshot.val()[tables[i]][tableItem[j]]])
                            if (this.props.location.state.reconciliationList[heading][snapshot.val()[tables[i]][tableItem[j]]] !== undefined) {
                                for (k = 0; k < this.props.location.state.reconciliationList[heading][snapshot.val()[tables[i]][tableItem[j]]].length; k++) {
                                    reconciliation[i][j].push(this.props.location.state.reconciliationList[heading][snapshot.val()[tables[i]][tableItem[j]]][k].quantity);
                                }
                            } else {
                                if (snapshot.val()[tables[i]][tableItem[j]] === 'Start') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(this.state.startValues[reconciliation[i][0][0]][reconciliation[i][0][k]])
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Sales') {
                                    //subtract
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][1][k] - reconciliation[i][2][k])
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Price') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        if (reconciliation[i-1][3][k] > 0) {
                                            reconciliation[i][j].push(reconciliation[i][3][k]/reconciliation[i-1][3][k]);
                                        } else {
                                            reconciliation[i][j].push(0);
                                        }
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Total Product') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][1][k] + reconciliation[i][2][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Inventory Sales') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][3][k] - reconciliation[i][4][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Meter Sales') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        if (k > 1) {
                                            reconciliation[i][j].push((reconciliation[i-2][3][4]/2) + reconciliation[i-2][3][k]);
                                        } else {
                                            reconciliation[i][j].push(reconciliation[i-2][3][k]);
                                        }
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Difference') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][6][k] - reconciliation[i][5][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Purchase Price') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        if (reconciliation[i][2][k] > 0) {
                                            reconciliation[i][j].push(reconciliation[i][8][k]/reconciliation[i][2][k]);
                                        } else {
                                            reconciliation[i][j].push(0);
                                        }
                                    }  
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Margin') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i-1][4][k] - reconciliation[i][9][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]].split(" ")[0] === 'Total') {
                                    var total = 0;
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        total = total + reconciliation[i][3][k];
                                    }
                                    reconciliation[i][j].push(total);
                                }
                            }
                        }
                        this.setState(reconciliation);
                    }
                }
            }
        });
        */
        this.setState({
            loaded: true,
        })
    }

    content () {
        return (
            <div className='new-order-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container'>
                    <h4>Monthly Reconciliation Data</h4>
                </div>
            </div> 
        )
    }

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}
  
export default ReconciliationMonthlySummaryPage;