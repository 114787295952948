import React, { Component } from 'react';
import ReconciliationItem from '../reconciliation-item/reconciliation-item.component';
import './reconciliation-container.styles.css'

class ReconciliationContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    };

    render () {
        return (
            <div className={this.props.styling}>
                <h5>{this.props.heading}</h5>
                <h6 className='info'>{this.props.info}</h6>
                <h6>{this.props.subheading}</h6>
                {this.props.data.map(data => (
                    <ReconciliationItem name={data.name} key={this.props.heading[0] + this.props.subheading[0] + data.name[0]} heading={this.props.heading} subheading={this.props.subheading} item={data} handleChange={this.props.handleChange}></ReconciliationItem>
                ))}
            </div>
    )};
}
  
export default ReconciliationContainer;