import React, { Component } from 'react';
import app from '../../../firebase/firebase.utils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {Table, Spinner, Button} from 'react-bootstrap';
import Header from '../../header/header.component';
import './reconciliation-summary-page.styles.css';
import '../../components.styles.css';
import { Link } from 'react-router-dom';

class ReconciliationSummaryPage extends Component {
    constructor(props) {
        super(props);

        var currentDate = this.props.location.state.currentDate;
        this.state = {
            currentDate: currentDate,
            reconciliation: [],
            gas: [],
            loaded: false,
            verificationSpinner: false,
            submitted: false,
            dispName: null,
            isAdmin: false,
            startValues: {}
        }
    };

    async componentDidMount () {
        if (this.props.location.state.displayName === 'Admin') {
            if (this.props.location.state.store !== undefined) {
                var dispName = this.props.location.state.store;
                this.setState({
                    isAdmin: true,
                })
                await app.database().ref('reconciliation/pastReconciliation/' + this.props.location.state.date[2] + "/" +  this.props.location.state.date[0] + "/" +  this.props.location.state.date[1] + "/" + this.props.location.state.name).once('value', snapshot => {
                    if (snapshot.val() !== null) {
                        var tables = Object.keys(snapshot.val());
                        for (var i = 0; i < tables.length - 1; i++) {
                            this.state.startValues[tables[i]] = (snapshot.val()[tables[i]]['Start'])
                        }
                    }
                });
                this.setState({
                    month: this.props.location.state.date[0],
                    date: this.props.location.state.date[1],
                    year: this.props.location.state.date[2],
                })
            } else {
                // eslint-disable-next-line
                var dispName = this.props.location.state.activeUser;
                await app.database().ref('reconciliationData/' + dispName + '/gasData/last').once('value', snapshot => {
                    if (snapshot.val() !== null) {
                        this.state.startValues = snapshot.val();
                    }
                });

                this.setState({
                    month: this.state.currentDate.getMonth()+1,
                    date: this.state.currentDate.getDate(),
                    year: this.state.currentDate.getFullYear(),
                })
            }
        } else {
            // eslint-disable-next-line
            var dispName = this.props.location.state.displayName;
            await app.database().ref('reconciliationData/' + dispName + '/gasData/last').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    this.state.startValues = snapshot.val();
                }
            });
            this.setState({
                month: this.state.currentDate.getMonth()+1,
                date: this.state.currentDate.getDate(),
                year: this.state.currentDate.getFullYear(),
            })
        }

        await app.database().ref('reconciliationData/' + dispName + '/gasData/gas').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var gas = Object.keys(snapshot.val());
                for (var i = 0; i < gas.length; i++) {
                    this.state.gas.push(snapshot.val()[gas[i]]);
                }
            }
        });

        await app.database().ref('reconciliationData/general/gasData/gasTables').once('value', snapshot => {
            var k = 0;
            if (snapshot.val() !== null) {
                var tables = Object.keys(snapshot.val());
                for (var i = 0; i < tables.length; i++) {
                    var tableItem = Object.keys(snapshot.val()[tables[i]]);
                    for (var j = 0; j < tableItem.length; j++) {
                        const reconciliation = this.state.reconciliation;
                        if (j === 0) {
                            var heading = snapshot.val()[tables[i]][tableItem[j]];
                            reconciliation.push([]);
                            reconciliation[i].push([heading]);
                            if (heading === 'Inventory') {
                                for (k = 0; k < this.state.gas.length-1; k ++) {
                                    reconciliation[i][j].push(this.state.gas[k])
                                }
                            } else {
                                for (k = 0; k < this.state.gas.length; k ++) {
                                    reconciliation[i][j].push(this.state.gas[k])
                                }
                            }
                        } else {
                            reconciliation[i].push([snapshot.val()[tables[i]][tableItem[j]]])
                            if (this.props.location.state.reconciliationList[heading][snapshot.val()[tables[i]][tableItem[j]]] !== undefined) {
                                for (k = 0; k < this.props.location.state.reconciliationList[heading][snapshot.val()[tables[i]][tableItem[j]]].length; k++) {
                                    reconciliation[i][j].push(this.props.location.state.reconciliationList[heading][snapshot.val()[tables[i]][tableItem[j]]][k].quantity);
                                }
                            } else {
                                if (snapshot.val()[tables[i]][tableItem[j]] === 'Start') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(this.state.startValues[reconciliation[i][0][0]][reconciliation[i][0][k]])
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Sales') {
                                    //subtract
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][1][k] - reconciliation[i][2][k])
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Price') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        if (reconciliation[i-1][3][k] > 0) {
                                            reconciliation[i][j].push(reconciliation[i][3][k]/reconciliation[i-1][3][k]);
                                        } else {
                                            reconciliation[i][j].push(0);
                                        }
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Total Product') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][1][k] + reconciliation[i][2][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Inventory Sales') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][3][k] - reconciliation[i][4][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Meter Sales') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        if (k > 1) {
                                            reconciliation[i][j].push((reconciliation[i-2][3][4]/2) + reconciliation[i-2][3][k]);
                                        } else {
                                            reconciliation[i][j].push(reconciliation[i-2][3][k]);
                                        }
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Difference') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i][6][k] - reconciliation[i][5][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Purchase Price') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        if (reconciliation[i][2][k] > 0) {
                                            reconciliation[i][j].push(reconciliation[i][8][k]/reconciliation[i][2][k]);
                                        } else {
                                            reconciliation[i][j].push(0);
                                        }
                                    }  
                                } else if (snapshot.val()[tables[i]][tableItem[j]] === 'Margin') {
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        reconciliation[i][j].push(reconciliation[i-1][4][k] - reconciliation[i][9][k]);
                                    }
                                } else if (snapshot.val()[tables[i]][tableItem[j]].split(" ")[0] === 'Total') {
                                    var total = 0;
                                    for (k = 1; k < reconciliation[i][0].length; k++) {
                                        total = total + reconciliation[i][3][k];
                                    }
                                    reconciliation[i][j].push(total);
                                }
                            }
                        }
                        this.setState(reconciliation);
                    }
                }
            }
        });
        console.log(this.state.startValues)
        console.log(this.state.reconciliation)
        this.setState({
            loaded: true,
            dispName: dispName,
        })
    }

    async handleVerify () {
        this.setState({
            verificationSpinner: true,
        });

        jsPDF.autoTableSetDefaults({
            headStyles: { 
                fillColor: [255, 255, 255],
                textColor: 0,
            },
            tableLineColor: 0,
            tableLineWidth: 1,
            styles: {
                lineColor: 0,
                lineWidth: 1,
            },
            bodyStyles: {
                textColor: 0,
            },
        })
        
        //output jspdf into blob
        var doc = new jsPDF('p', 'pt', 'letter');
        doc.text('Gas reconciliation created by ' + this.state.dispName + ' for ' + this.state.month + "-" + this.state.date + "-" + this.state.year, 15, 20)
        var i = 0;
        for (i = 0; i < this.state.reconciliation.length; i++) {
            doc.autoTable({
                html: '#' + this.state.reconciliation[i][0][0].split(" ")[0], 
                theme: 'grid',
            });
        }
        var file = doc.output('blob');

        //get currentID
        var currentID = null;
        await app.database().ref('reconciliation/currentID').once('value', snapshot => {
            currentID = snapshot.val();
        });

        //upload file
        var storageRef = app.storage().ref().child('/' + this.state.dispName + "/Reconciliation/" + this.state.year + "/" + this.state.month + "/" + this.state.date + "/" +  currentID);
        await storageRef.put(file).then(function(snapshot) {
            console.log('Uploaded file!');
        });

        //get download url
        var downloadURL = '';
        await storageRef.getDownloadURL().then(url => {
            downloadURL = url;
        })

        //update db
        app.database().ref('files/' + this.state.dispName + "/Reconciliation/r" + currentID).set({
            name: "r" + currentID,
            dateCreated: this.state.month + "-" + this.state.date + "-" + this.state.year,
            downloadURL: downloadURL,
            category: "Reconciliation",
            ordered: false,
        });
        
        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/r" + currentID).set({
            store: this.state.dispName
        });

        var k = 1;
        for (i = 0; i < this.state.reconciliation.length; i++) {
            for (var j = 1; j < this.state.reconciliation[i].length; j++) {
                if (this.state.reconciliation[i][j][0] === "Ending") {
                    for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                        await app.database().ref('reconciliationData/' + this.state.dispName + "/gasData/last/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/r" + currentID + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][j][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                    }
                } else if (this.state.reconciliation[i][j][0].split(" ")[0] === "Total" && this.state.reconciliation[i][0][0].split(" ")[0] !== "Inventory") {
                    for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/r" + currentID + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][j][0]).set({
                            quantity: this.state.reconciliation[i][j][k],
                        });
                    }
                } else {
                    for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/r" + currentID + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][j][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                    }
                }
            }
        }

        var monthlyData = null;
        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/").once('value', snapshot => {
            monthlyData = snapshot.val();
        });

        if (monthlyData != null) {
            for (i = 0; i < this.state.reconciliation.length; i++) {
                for (j = 1; j < this.state.reconciliation[i].length; j++) {
                    if (this.state.reconciliation[i][0][0].split(" ")[0] !== "Inventory") {
                        if (this.state.reconciliation[i][j][0] === "Sales") {
                            console.log(this.state.reconciliation[i][0][0])
                            console.log(this.state.reconciliation[i][j])
                            for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                                await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k] + monthlyData[this.state.reconciliation[i][0][0]][this.state.reconciliation[i][0][k]]);
                            }
                        }
                    } else {
                        if (['Delivery Price', 'Deliveries', 'Difference'].indexOf(this.state.reconciliation[i][j][0]) > -1) {
                            for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                                await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/" + this.state.reconciliation[i][j][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k] + monthlyData[this.state.reconciliation[i][j][0]][this.state.reconciliation[i][0][k]]);
                            }
                        }
                    }
                }
            }
        } else {
            for (i = 0; i < this.state.reconciliation.length; i++) {
                for (j = 1; j < this.state.reconciliation[i].length; j++) {
                    if (this.state.reconciliation[i][0][0].split(" ")[0] !== "Inventory") {
                        if (this.state.reconciliation[i][j][0] === "Sales") {
                            for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                                await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                            }
                        }
                    } else {
                        if (['Delivery Price', 'Deliveries', 'Difference'].indexOf(this.state.reconciliation[i][j][0]) > -1) {
                            for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                                await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/" + this.state.reconciliation[i][j][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                            }
                        }
                    }
                }
            }
        }
        
        //add to currentID
        var updates = {};
        updates['/reconciliation/currentID'] = currentID+1;
        await app.database().ref().update(updates);

        this.setState({
            verificationSpinner: false,
            submitted: true,
            redirect: true,
        });
    }

    async handleEdit () {
        this.setState({
            verificationSpinner: true,
        });
        
        var pastReconciliation = null;
        await app.database().ref('reconciliation/pastReconciliation/' + this.props.location.state.date[2] + "/" +  this.props.location.state.date[0] + "/" +  this.props.location.state.date[1] + "/" + this.props.location.state.name).once('value', snapshot => {
            if (snapshot.val() !== null) {
                pastReconciliation = snapshot.val();
            }
        });

        var reconciliationDataLast = null;
        await app.database().ref('reconciliationData/' + this.props.location.state.store + "/gasData/last").once('value', snapshot => {
            if (snapshot.val() !== null) {
                reconciliationDataLast = snapshot.val();
            }
        });

        var tables = Object.keys(reconciliationDataLast);
        for (var i = 0; i < tables.length; i++) {
            var gasTypes = Object.keys(reconciliationDataLast[tables[i]]);
            for (var j = 0; j < gasTypes.length; j++){
                await app.database().ref('reconciliationData/' + this.props.location.state.store  + "/gasData/last/" + tables[i] + "/" + gasTypes[j]).set(reconciliationDataLast[tables[i]][gasTypes[j]] - pastReconciliation[tables[i]]['Ending'][gasTypes[j]]);
            }
        }
        
        //remove from DB
        await app.database().ref('files/' + this.props.location.state.store  + '/Reconciliation/' + this.props.location.state.name).remove();
        await app.database().ref('reconciliation/pastReconciliation/' +  this.props.location.state.date[2] + "/" +  this.props.location.state.date[0] + "/" +  this.props.location.state.date[1] + "/" + this.props.location.state.name).remove();

        //remove from storage
        var deleteRef = app.storage().ref().child('/' + this.props.location.state.store  + '/Reconciliation/' + this.props.location.state.date[2] + "/" + this.props.location.state.date[0] + "/" + this.props.location.state.date[1] + "/" + this.props.location.state.name);
        await deleteRef.delete().then(function() {
            // File deleted successfully
            }).catch(function(error) {
            // Uh-oh, an error occurred!
        });

        jsPDF.autoTableSetDefaults({
            headStyles: { 
                fillColor: [255, 255, 255],
                textColor: 0,
            },
            tableLineColor: 0,
            tableLineWidth: 1,
            styles: {
                lineColor: 0,
                lineWidth: 1,
            },
            bodyStyles: {
                textColor: 0,
            },
        })
        
        //output jspdf into blob
        var doc = new jsPDF('p', 'pt', 'letter');
        doc.text('Gas reconciliation created by ' + this.state.dispName + ' for ' + this.state.month + "-" + this.state.date + "-" + this.state.year, 15, 20)

        for (i = 0; i < this.state.reconciliation.length; i++) {
            doc.autoTable({
                html: '#' + this.state.reconciliation[i][0][0].split(" ")[0], 
                theme: 'grid',
            });
        }
        var file = doc.output('blob');

        //upload file
        var storageRef = app.storage().ref().child('/' + this.state.dispName + "/Reconciliation/" + this.props.location.state.date[2] + "/" +  this.props.location.state.date[0] + "/" +  this.props.location.state.date[1] + "/" + this.props.location.state.name);
        await storageRef.put(file).then(function(snapshot) {
            console.log('Uploaded file!');
        });

        //get download url
        var downloadURL = '';
        await storageRef.getDownloadURL().then(url => {
            downloadURL = url;
        })

        //update db
        app.database().ref('files/' + this.state.dispName + "/Reconciliation/r" + this.props.location.state.name).set({
            name: this.props.location.state.name,
            dateCreated: this.state.month + "-" + this.state.date + "-" + this.state.year,
            downloadURL: downloadURL,
            category: "Reconciliation",
            ordered: false,
        });
        
        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/" + this.props.location.state.name).set({
            store: this.state.dispName
        });

        var k = 1;
        for (i = 0; i < this.state.reconciliation.length; i++) {
            for (j = 1; j < this.state.reconciliation[i].length; j++) {
                if (this.state.reconciliation[i][j][0] === "Ending") {
                    for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                        await app.database().ref('reconciliationData/' + this.state.dispName + "/gasData/last/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/" + this.props.location.state.name + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][j][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                    }
                } else if (this.state.reconciliation[i][j][0].split(" ")[0] === "Total" && this.state.reconciliation[i][0][0].split(" ")[0] !== "Inventory") {
                    for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/" + this.props.location.state.name + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][j][0]).set({
                            quantity: this.state.reconciliation[i][j][k],
                        });
                    }
                } else {
                    for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/" + this.props.location.state.name + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][j][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k]);
                    }
                }
            }
        }

        var monthlyData = null;
        await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/").once('value', snapshot => {
            monthlyData = snapshot.val();
        });

        for (i = 0; i < this.state.reconciliation.length; i++) {
            for (j = 1; j < this.state.reconciliation[i].length; j++) {
                if (this.state.reconciliation[i][0][0].split(" ")[0] !== "Inventory") {
                    if (this.state.reconciliation[i][j][0] === "Sales") {
                        for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                            await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/" + this.state.reconciliation[i][0][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k] + monthlyData[this.state.reconciliation[i][0][0]][this.state.reconciliation[i][0][k]] - pastReconciliation[this.state.reconciliation[i][0][0]][this.state.reconciliation[i][j][0]][this.state.reconciliation[i][0][k]]);
                        }
                    }
                } else {
                    if (['Delivery Price', 'Deliveries', 'Difference'].indexOf(this.state.reconciliation[i][j][0]) > -1) {
                        for (k = 1; k < this.state.reconciliation[i][j].length; k++) {
                            await app.database().ref('reconciliation/pastReconciliation/' + this.state.year + "/" +  this.state.month + "/monthlyData/" + this.state.dispName + "/" + this.state.reconciliation[i][j][0] + "/" + this.state.reconciliation[i][0][k]).set(this.state.reconciliation[i][j][k] + monthlyData[this.state.reconciliation[i][j][0]][this.state.reconciliation[i][0][k]] - pastReconciliation[this.state.reconciliation[i][0][0]][this.state.reconciliation[i][j][0]][this.state.reconciliation[i][0][k]]);
                        }
                    }
                }
            }
        }

        this.setState({
            verificationSpinner: false,
            submitted: true,
            redirect: true,
        });
    }

    render () {
        if (this.state.redirect) {
            return (
            <div className='reconciliation-summary-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container container-with-footer'>
                    <h4>Submitted!</h4>
                        <div className='sticky-footer'>
                            {this.state.submitted? <Button className='float-right' variant="success">Submitted</Button> : this.state.verificationSpinner? 
                            <Button className='float-right' variant="warning">Uploading...<Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button className='float-right' variant="warning" onClick={this.handleVerify.bind(this)}>Submit</Button>}        
                        </div>
                    </div>
                </div>
                )
        } else {
            return (
                <div className='reconciliation-summary-page'>
                    <Header displayName={this.props.location.state.displayName}></Header>
                    <div className='container container-with-footer'>
                        <h5 className='verify-message'>Please verify 'Total Sales' with Shift Summary and 'Total Volume' with Inventory Report.</h5>
                        <hr></hr>
                        <h4 id='summary-table-header'>Gas reconciliation created by {this.state.dispName} for {this.state.month + "-" + this.state.date + "-" + this.state.year}</h4>
                        <div className='order-summary-page' id='summary-page'>
                            {this.state.reconciliation.map(function(reconciliationGroup) {
                                return (
                                    <Table bordered striped id={reconciliationGroup[0][0].split(" ")[0]}>
                                        {reconciliationGroup.map(function(reconciliationItem, idx, ) {
                                            if (idx > 0) {
                                                if (reconciliationItem.length !== undefined) {
                                                    return (
                                                        <tbody>
                                                            <tr>
                                                                {reconciliationItem.map(reconciliationCell => (
                                                                    <td>
                                                                        {typeof(reconciliationCell) === "number"? reconciliationCell.toFixed(2) : reconciliationCell}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        </tbody>
                                                    )
                                                } else {
                                                    return false;
                                                }
                                            } else {
                                                if (reconciliationItem.length !== undefined) {
                                                    return (
                                                        <thead>
                                                            <tr>
                                                                {reconciliationItem.map(reconciliationCell => (
                                                                    <td>
                                                                        {typeof(reconciliationCell) === "number"? reconciliationCell.toFixed(2) : reconciliationCell}
                                                                    </td>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                    )
                                                } 
                                                else {
                                                    return false;
                                                }
                                            }
                                        })}
                                    </Table>
                                )
                            })}
                            <div className='sticky-footer'>
                                {this.state.submitted? <Button className='float-right' variant="success">Submitted</Button> : this.state.verificationSpinner? 
                                <Button className='float-right' variant="warning">Uploading...<Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                                : this.state.isAdmin? <Button className='float-right' variant="warning" onClick={this.handleEdit.bind(this)}>Edit</Button> : <Button className='float-right' variant="warning" onClick={this.handleVerify.bind(this)}>Submit</Button>}
                                {this.state.isAdmin? <Link to={{pathname: "/reconciliation/edit", state: { displayName: this.props.location.state.displayName, reconciliationList: this.props.location.state.reconciliationList, store: this.state.dispName, date: this.props.location.state.date, name: this.props.location.state.name }}}>
                                    <Button className='float-left' variant="secondary">Back</Button>
                                </Link> : 
                                <Link to={{pathname: "/reconciliation/new", state: { displayName: this.props.location.state.displayName, reconciliationList: this.props.location.state.reconciliationList }}}>
                                <Button className='float-left' variant="secondary">Back</Button>
                            </Link>
                                }
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    };
}
  
export default ReconciliationSummaryPage;