import React, { Component } from 'react';
import './new-order-page.styles.css';
import '../../components.styles.css';
import app from '../../../firebase/firebase.utils';
import InventoryItem from '../inventory-item/inventory-item.component';
import LoadingPage from '../../loading-page/loading-page.component';
import { Link } from "react-router-dom";
import Header from '../../header/header.component';
import {Button, Table, Modal, Form, Spinner} from 'react-bootstrap';

class NewOrderPage extends Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            isAdmin: false,
            inventory: {},
            categories: [],
            filteredCategories: [],

            order: {},
            orderList: {},

            isOther: false,

            showEditModal: false,
            editInventorySpinner: false,
            oldItemName: '',
            oldItemCategory: '',
            oldItemKey: '',
            
            showAddModal: false,
            addInventorySpinner: false,
            itemName: '',
            itemCategory: '',
            itemKey: '',

            showDeleteModal: false,
            deleteInventorySpinner: false,
            deleteItemName: '',
            deleteItemCategory: '',
            deleteItemKey: '',

            updateDataFromExcelSpinner: false,

            searchField: '',
        }
    };

    async componentDidMount(){
        if (this.props.location.state.displayName === 'Admin') {
            this.setState({
                isAdmin: true,
                containerNewOrder: { "paddingTop": "180px"},
                stickyTableHeader: { "top": "190px"},
                stickyCategory: {"top": "220px"}
            });
        } else {
            this.setState({
                containerNewOrder: { "paddingTop": "110px"},
                stickyTableHeader: { "top": "120px"},
                stickyCategory: {"top": "150px"}
            });
        }
        
        //reading from DB
        var categories = [];
        await app.database().ref('inventory/products').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var products = Object.keys(snapshot.val());
                for (var i = 0; i < products.length; i++) {
                    if (snapshot.val()[products[i]].active) {
                        var productCategory = snapshot.val()[products[i]].category;
                        if (categories.indexOf(productCategory) >= 0) {
                            const inventory = this.state.inventory;
                            this.state.inventory[productCategory].push(snapshot.val()[products[i]]);
                            this.setState(inventory);
                        } else {
                            categories.push(productCategory);
                            const inventory = this.state.inventory;
                            this.state.inventory[productCategory] = [snapshot.val()[products[i]]];
                            this.setState(inventory);
                        }
                    }
                }
            }
        });

        categories.sort();

        var a = [];
        for (var i = 0; i < categories.length; i++) {
            a.push(categories[i]);
        }
        this.setState({
            filteredCategories: a,
        })

        this.setState({ 
            categories: categories,
        });

        if (categories.length > 0) {
            this.setState({
                itemCategory: categories[0]
        })} else {
            this.setState({
                isOther: true
        })}

        this.setState({
            loaded: true
        })
    }

    onInclude = (item) => {
        const orderList = this.state.orderList;
        orderList[item.key] = item;
        this.setState(orderList); 
    
        if (this.state.order[item.category] === undefined) {
            const order = this.state.order;
            order[item.category] = [item];
            this.setState(order); 
        } else {
            const order = this.state.order;
            order[item.category].push(item);
            order[item.category].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
            this.setState(order); 
        }
    }

    onExclude = (item) => {
        const orderList = this.state.orderList;
        delete orderList[item.key]
        this.setState(orderList);

        for (var i = 0; i < this.state.order[item.category].length; i++) {
            if (this.state.order[item.category][i].name === item.name) {
                const order = this.state.order;
                order[item.category].splice(i, 1);
                this.setState(order);
                break;
            }
        }

        if (this.state.order[item.category].length === 0) {
            const order = this.state.order;
            delete order[item.category];
            this.setState(order);
        }
    }

    async deleteInventory () {
        this.setState({
            deleteInventorySpinner: true
        })
        
        //update db
        var updates = {};
        updates['/inventory/products/' + this.state.deleteItemKey + '/active'] = false;
        await app.database().ref().update(updates);

        //delete from local
        var deleteItemKey = this.state.deleteItemKey;
        const inventory = this.state.inventory;
        inventory[this.state.deleteItemCategory] = this.state.inventory[this.state.deleteItemCategory].filter(function(inventory) {
            return inventory.key !== deleteItemKey;
        });
        this.setState(inventory);
        
        this.setState({
            deleteInventorySpinner: false,
            showDeleteModal: false
        })
    }
    
    deleteFromDB = (item) => {
        this.setState({
            deleteItemName: item.name,
            deleteItemCategory: item.category,
            deleteItemKey: item.key,
            showDeleteModal: !this.state.showDeleteModal
        })
    }

    editFromDB = (item) => {
        this.setState({
            itemCategory: item.category,
            itemName: item.name,
            oldItemName: item.name,
            oldItemKey: item.key,
            oldItemCategory: item.category,
            showEditModal: !this.state.showEditModal
        })
    }

    showEditModal () {
        this.setState({
            showEditModal: !this.state.showEditModal
        });
    }

    async editInventory () {
        this.setState({
            editInventorySpinner: true,
        });

        if (this.state.oldItemName !== this.state.itemName || this.state.oldItemCategory !== this.state.itemCategory) {
            var updates = {};
            updates['/inventory/products/' + this.state.oldItemKey + '/category'] = this.state.itemCategory;
            updates['/inventory/products/' + this.state.oldItemKey + '/name'] = this.state.itemName;
            await app.database().ref().update(updates);

            //remove from local storage
            var oldItemName = this.state.oldItemName;
            const inventory = this.state.inventory;
            inventory[this.state.oldItemCategory] = this.state.inventory[this.state.oldItemCategory].filter(function(inventory) {
                return inventory.name !== oldItemName;
            });
            this.setState(inventory);
            
            //add to local storage
            var itemObj = {name: this.state.itemName, category: this.state.itemCategory, key: this.state.oldItemKey};
            if (this.state.inventory[this.state.itemCategory] !== undefined) {
                const inventory = this.state.inventory;
                inventory[this.state.itemCategory].push(itemObj);
                inventory[this.state.itemCategory].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
                this.setState(inventory); 
            } else {
                const inventory = this.state.inventory;
                inventory[this.state.itemCategory] = [itemObj];
                this.setState(inventory); 
                const categories = this.state.categories;
                categories.push(this.state.itemCategory)
                this.setState(categories);
            }
        }

        this.setState({
            editInventorySpinner: false,
            showEditModal: !this.state.showEditModal,
        });
    }

    showDeleteModal () {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        });
    }

    showAddModal () {
        this.setState({
            showAddModal: !this.state.showAddModal
        });
    }

    handleItemCategoryChange(event) {
        if (event.target.value === 'Other') {
            this.setState({
                isOther: true
            })
        } else {
            this.setState({
                isOther: false
            })
        }
        this.setState({
            itemCategory: event.target.value,
        });
    }

    handleNewItemCategory(event) {
        this.setState({
            itemCategory: event.target.value,
        });
    }

    handleItemNameChange(event) {
        this.setState({
            itemName: event.target.value,
        });
    }

    async addInventory () {
        if (this.state.itemCategory !== "" && this.state.itemName !== "") {
            this.setState({
                addInventorySpinner: true
            })

            //get currentID
            var currentID = null;
            await app.database().ref('inventory/currentID').once('value', snapshot => {
                currentID = snapshot.val();
            });

            //add new item at currentID
            await app.database().ref('inventory/products/' + currentID).set({
                name: this.state.itemName,
                category: this.state.itemCategory,
                active: true,
                key: currentID
            });

            //add to currentID
            var updates = {};
            updates['/inventory/currentID'] = currentID+1;
            await app.database().ref().update(updates);

            //update local
            var itemObj = {name: this.state.itemName, category: this.state.itemCategory, key: currentID};
            
            //add to local storage
            if (this.state.inventory[this.state.itemCategory] !== undefined) {
                const inventory = this.state.inventory;
                inventory[this.state.itemCategory].push(itemObj);
                inventory[this.state.itemCategory].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
                this.setState(inventory); 
            } else {
                const inventory = this.state.inventory;
                inventory[this.state.itemCategory] = [itemObj];
                this.setState(inventory); 
                const categories = this.state.categories;
                categories.push(this.state.itemCategory);
                categories.sort();
                this.setState(categories);
                const filteredCategories = this.state.filteredCategories;
                filteredCategories.push(this.state.itemCategory)
                filteredCategories.sort();
                this.setState(filteredCategories);
            }

            this.setState({
                showAddModal: !this.state.showAddModal,
                addInventorySpinner: false,
                isOther: false,
            });
        }
    }

    handleSearch (event) {
        this.setState({ 
            searchField: event.target.value,
        })
    }

    handleFilter (category) {
        var idx = this.state.filteredCategories.indexOf(category);
        if (idx >= 0) {
            this.state.filteredCategories.splice(idx, 1);
        } else {
            this.state.filteredCategories.splice(this.state.categories.indexOf(category), 0, category);
        }
        this.forceUpdate();
    }

    handleResetFilter () {
        var a = [];
        for (var i = 0; i < this.state.categories.length; i++) {
            a.push(this.state.categories[i]);
        }
        this.setState({
            filteredCategories: a,
        })
    }

    content () {
        const filteredInventory = {};
        
        for (var i = 0; i < this.state.filteredCategories.length; i++) {
            if (this.state.inventory[this.state.filteredCategories[i]] !== undefined){
                for (var j = 0; j < this.state.inventory[this.state.filteredCategories[i]].length; j++) {
                    if (this.state.inventory[this.state.filteredCategories[i]][j].name.toLowerCase().includes(this.state.searchField.toLowerCase())) {
                        if (filteredInventory[this.state.filteredCategories[i]] === undefined) {
                            filteredInventory[this.state.filteredCategories[i]] = [];
                        }
                        filteredInventory[this.state.filteredCategories[i]].push(this.state.inventory[this.state.filteredCategories[i]][j]);
                    }
                }
            }
        }

        return (
            <div className='new-order-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div>
                    <Modal show={this.state.showAddModal} onHide={this.showAddModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Inventory Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Item Name:</Form.Label>
                                    <Form.Control name="itemName" type="text" onChange={this.handleItemNameChange.bind(this)}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Item Category:</Form.Label>
                                    <Form.Control name="itemCategory" as="select" onChange={this.handleItemCategoryChange.bind(this)}>
                                        {this.state.categories.map(category => (
                                            <option key={category} value={category}>{category}</option>
                                        ))}
                                        <option key="Other">Other</option>
                                    </Form.Control>
                                </Form.Group>
                                {this.state.isOther? 
                                <Form.Group onChange={this.handleNewItemCategory.bind(this)}>
                                    <Form.Label>Category Name:</Form.Label>
                                    <Form.Control name="itemCategory" type="text" />
                                </Form.Group>
                                :false}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showAddModal.bind(this)}>Cancel</Button>
                            {this.state.addInventorySpinner? 
                            <Button variant="primary"><Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button variant="primary" onClick={this.addInventory.bind(this)}>Add Inventory</Button>}
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showEditModal} onHide={this.showEditModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Inventory Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Item Name:</Form.Label>
                                    <Form.Control name="itemName" type="text" defaultValue={this.state.oldItemName} onChange={this.handleItemNameChange.bind(this)}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Item Category:</Form.Label>
                                    <Form.Control  name="itemCategory" as="select" defaultValue={this.state.oldItemCategory} onChange={this.handleItemCategoryChange.bind(this)}>
                                        {this.state.categories.map(category => (
                                            <option key={category}>{category}</option>
                                        ))}
                                        <option key="Other">Other</option>
                                    </Form.Control>
                                </Form.Group>
                                {this.state.isOther? 
                                <Form.Group onChange={this.handleNewItemCategory.bind(this)}>
                                    <Form.Label>Category Name:</Form.Label>
                                    <Form.Control name="itemCategory" type="text" />
                                </Form.Group>
                                :false}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showEditModal.bind(this)}>Cancel</Button>
                            {this.state.editInventorySpinner? 
                            <Button variant="primary"><Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button variant="primary" onClick={this.editInventory.bind(this)}>Edit Inventory</Button>}                  
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showDeleteModal} onHide={this.showDeleteModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Inventory Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete {this.state.deleteItemName}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showDeleteModal.bind(this)}>Cancel</Button>
                            {this.state.deleteInventorySpinner? 
                            <Button variant="danger"><Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button variant="danger" onClick={this.deleteInventory.bind(this)}>Delete Inventory</Button>}
                        </Modal.Footer>
                    </Modal>

                    <div className='sticky-header' id='sticky-header'>
                        {this.state.isAdmin ? 
                        <div>
                            <Button className='admin-button' variant='secondary' onClick={this.showAddModal.bind(this)}>Add Inventory Item</Button>
                            <hr></hr>
                        </div>
                        : false}
                        <div className='float-left'>
                            {this.state.categories.map(category => (
                                this.state.filteredCategories.indexOf(category) >= 0? 
                                <Form.Check inline label={category} type="checkbox" key={category} name={category} onChange={this.handleFilter.bind(this, category)} checked></Form.Check> 
                                : <Form.Check inline label={category} type="checkbox" key={category} name={category} onChange={this.handleFilter.bind(this, category)}></Form.Check>
                            ))}
                            <Button size="sm" onClick={this.handleResetFilter.bind(this)}>Reset Filters</Button>
                        </div>
                        <div className='float-right search-button'>
                            <span>Search: </span>
                            <input type='search' onChange={this.handleSearch.bind(this)}></input>
                        </div>
                    </div>
                    
                    <div className='container-with-footer' style={this.state.containerNewOrder}>
                        <Table hover size='sm'>
                            <thead>
                                <tr>
                                    <th className='sticky-table-header' style={this.state.stickyTableHeader}>Category</th>
                                    <th className='sticky-table-header'style={this.state.stickyTableHeader}>Name</th>
                                    <th className='sticky-table-header'style={this.state.stickyTableHeader}>Quantity</th>
                                    {this.state.isAdmin? <th className='sticky-table-header' style={this.state.stickyTableHeader}>Edit?</th>  : false}
                                    {this.state.isAdmin? <th className='sticky-table-header' style={this.state.stickyTableHeader}>Delete?</th>  : false}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(filteredInventory).map(category => (
                                    <React.Fragment key={category}>
                                        <tr>
                                            <td className='sticky-category' style={this.state.stickyCategory}>{category}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {filteredInventory[category].map(item => (                         
                                            <InventoryItem key={item.key} isAdmin={this.state.isAdmin} deleteFromDB={this.deleteFromDB} editFromDB={this.editFromDB} item={item} quantity={this.state.orderList[item.key]===undefined? '': this.state.orderList[item.key].quantity} include={this.onInclude} exclude={this.onExclude}></InventoryItem>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                    <div className='sticky-footer'>
                        <Link to={{pathname: "/inventory/summary", state: { order: this.state.order, orderList: this.state.orderList, displayName: this.props.location.state.displayName }}}>
                            <Button className='float-right'>Submit</Button>
                        </Link>
                    </div>
                </div>
            </div>
    )}

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}
  
export default NewOrderPage;