import React, { Component } from 'react';
import Header from '../header/header.component';
import '../components.styles.css';
import { Spinner } from 'react-bootstrap';

class LoadingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    };

    render () {
        return (
            <div>
                <Header displayName={this.props.displayName}></Header>
                <div className='container'>
                    <h4>Loading <Spinner animation="border" role="status"></Spinner></h4>
                </div>
            </div>
    )};
}
  
export default LoadingPage;