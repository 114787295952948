import React, { Component } from 'react';
import app from '../../../firebase/firebase.utils';
import Header from '../../header/header.component';
import { Link } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import LoadingPage from '../../loading-page/loading-page.component';

class MonthlyReconciliationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            years: [],
            months: [],
            users: [],
            selectedYear: null,
            selectedMonth: null,
            selectedUser: null,
            loaded: false,
        }
    };

    async componentDidMount() {
        await app.database().ref('reconciliation/pastReconciliation/').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var years = Object.keys(snapshot.val());
                this.setState({
                    years: years,
                    selectedYear: years[0]
                })
                for (var i = 0; i < years.length; i++) {
                    var monthYear = Object.keys(snapshot.val()[years[i]])
                    const months = this.state.months;
                    months.push(monthYear);
                    this.setState(months);
                }
            }
        });

        await app.database().ref('users').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var users = Object.keys(snapshot.val());
                for (var i = 0; i < users.length; i++) {
                    if (snapshot.val()[users[i]].name !== 'Admin') {
                        if (i === 1) {
                            var firstUser = snapshot.val()[users[i]].name;
                            this.setState({
                                selectedUser: firstUser
                            })
                        }
                        this.state.users.push(snapshot.val()[users[i]])
                    }
                    
                }
            }
        });

        this.setState({
            loaded: true,
        })

    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    content () {
        return (
            <div className='inventory-page'> 
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container'>
                    <h4>Monthly Reconciliation Summary</h4>
                    <Form>
                        <Form.Group>
                            <Form.Label>Reconciliation for:  </Form.Label>
                            <Form.Control name="selectedUser" as="select" onChange={this.handleChange.bind(this)}>
                                {this.state.users.map(function(user, userIdx, ) {
                                    return (
                                    <option key={userIdx}>{user.name}</option>
                                )})}
                            </Form.Control>    
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select Year</Form.Label>
                            <Form.Control name="selectedYear" as="select" onChange={this.handleChange.bind(this)}>
                                {this.state.years.map(function(year, yearIdx, ) {
                                    return (
                                        <option key={yearIdx}>{year}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select Month</Form.Label>
                            <Form.Control name="selectedMonth" as="select" onChange={this.handleChange.bind(this)}>
                                {this.state.months[this.state.years.indexOf(this.state.selectedYear)].map(function(month, monthIdx, ) {
                                    return (
                                        <option key={monthIdx}>{month}</option>
                                    )
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    <div className='sticky-footer'>
                        <Link to={{pathname: "/reconciliation/monthSummary", state: { selectedYear: this.state.selectedYear, selectedMonth: this.state.selectedMonth, displayName: this.props.location.state.displayName, selectedUser: this.state.selectedUser }}}>
                            <Button className='float-right'>Submit</Button>
                        </Link>
                    </div>
                </div>
            </div> 
        )
    }

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}

export default MonthlyReconciliationPage;