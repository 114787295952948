import React, { Component } from 'react';
import Header from '../../header/header.component';
import app from '../../../firebase/firebase.utils';
import './manage-employee-page.styles.css'
import {Button, Form, Table} from 'react-bootstrap';

class EmployeeManager extends Component {

    constructor() {
        super();

        this.state = {
            employees: [],
            currentDispName: "",
            dispName: [],
            firstName: "",
        }
    };

    async componentDidMount() {
        var dispName = [];
        if (this.props.location.state.displayName === 'Admin') {
            this.setState({
                isAdmin: true,
            })
            var firstUser = null;
            await app.database().ref('users').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var users = Object.keys(snapshot.val());
                    for (var i = 0; i < users.length; i++) {
                        if (snapshot.val()[users[i]].name !== 'Admin') {
                            if (i === 1) {
                                firstUser = snapshot.val()[users[i]].name;
                                this.setState({
                                    currentDispName: firstUser,
                                    dispName: [firstUser]
                                })
                            } else {
                                this.state.dispName.push(snapshot.val()[users[i]]['name'])
                            }
                            
                            dispName.push(snapshot.val()[users[i]]['name'])
                        }
                        
                    }
                }
            });
        } else {
            this.setState({
                currentDispName: this.props.location.state.displayName,
                dispName: [this.props.location.state.displayName]
            })
            dispName = [this.props.location.state.displayName]
        }

        await app.database().ref('schedule/').once('value', snapshot => {
            for (var i = 0; i < dispName.length; i++) {
                if (this.state.employees[dispName[i]] === undefined) {
                    const employees = this.state.employees;
                    employees[dispName[i]] = [];
                    this.setState(employees);
                }
                if (snapshot.val() !== null) {
                    if (snapshot.val()[dispName[i]] !== undefined) {
                        if (snapshot.val()[dispName[i]]['employee'] !== undefined) {
                            var keys = Object.keys(snapshot.val()[dispName[i]]['employee']);
                            for (var j = 0; j < keys.length; j++) {
                                const employees = this.state.employees;
                                employees[dispName[i]].push(snapshot.val()[dispName[i]]['employee'][keys[j]]);
                                this.setState(employees);
                            }
                        }
                    }
                }
            }
        });

        this.setState({ 
            loaded: true,
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name] : event.target.value 
        });
    }

    async addEmployee(event) {
        const employees = this.state.employees;
        employees[this.state.currentDispName].push({name: this.state.firstName});
        this.setState(employees);

        await app.database().ref('schedule/' + this.state.currentDispName + '/employee/' + this.state.firstName).set({
            name: this.state.firstName
        });

        this.setState({
            firstName: "",
            lastName: ""
        })
    }

    async deleteEmployee(employee, event) {
        const employees = this.state.employees;
        employees[this.state.currentDispName] = employees[this.state.currentDispName].filter(e => 
            e.name !== employee.name
        );
        this.setState(employees);
        await app.database().ref('schedule/' + this.state.currentDispName + '/employee/' + employee.name).remove();
    }

    handleUserChange = (user) => {
        this.setState({
            currentDispName: user
        });
    }

    render () {
        return (
            <div className='inventory-page'> 
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='schedule-container'>
                    <h4>Manage Employees</h4>
                    {this.state.isAdmin?
 <div>
 <Form.Group>
     <Form.Label>Employees for:  </Form.Label>
     <Form.Control as="select" onChange={this.handleUserChange.bind(this)}>
         {this.state.dispName.map(function(user, userIdx, ) {
             return (
             <option key={userIdx}>{user}</option>
         )}, this)}
     </Form.Control>   
 </Form.Group>
 <hr></hr>
 </div>
                    : false
                    }
                    {this.state.loaded?
                    <div>
                        <h5>Employee Data</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                            <th>Name</th>
                            <th>Delete?</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.employees[this.state.currentDispName].map(function(employee, employeeIdx, ) {
                            return (
                            <tr key={employeeIdx}>
                                <td>{employee.name}</td>
                                <td><Button size="sm" variant="danger" onClick={this.deleteEmployee.bind(this, employee)}>Delete</Button></td>
                            </tr>
                            
                        )}, this)}
                        </tbody>
                    </Table>
                    <h5>Add Employee</h5>
                    <div className="employee-container">
                        <Form.Control className="employee-input" name="firstName" value={this.state.firstName} placeholder="Enter name" onChange={this.handleChange.bind(this)}></Form.Control>
                        <Button className="employee-input" onClick={this.addEmployee.bind(this)}>Add Employee</Button>
                    </div>
                    
                            </div>
                : false    
                }
                </div>
            </div>
        )
    };
}
  
export default EmployeeManager;