import React, { Component } from 'react';
import './header.styles.css';
import '../components.styles.css'
import { NavLink } from 'react-router-dom';
import app from '../../firebase/firebase.utils'
import { Button } from 'react-bootstrap';
import Logo from '../../logos/logo_v3.jpg';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    };

    render () {
        return (
            <div className='header'>
                <div className='header-button'>    
                    <NavLink to={{pathname: "/", state: { displayName: this.props.displayName}}} style={{ textDecoration: 'none' }}>
                        <img className='header-logo' src={Logo} alt="Logo" />
                    </NavLink>
                </div>
                <div className='header-item'>  
                    <nav className='header-page-container'>
                        <NavLink activeClassName="header-active" className='header-page-item' to={{pathname: "/inventory", state: { displayName: this.props.displayName}}}>Inventory</NavLink>
                        {this.props.displayName === "Admin"? 
                        <NavLink activeClassName="header-active" className='header-page-item' to={{pathname: "/reconciliation", state: { displayName: this.props.displayName}}}>Reconciliation</NavLink> :
                        <NavLink activeClassName="header-active" className='header-page-item' to={{pathname: "/reconciliation/new", state: { displayName: this.props.displayName}}}>Reconciliation</NavLink>
                        }
                        {this.props.displayName === "Admin"? 
                        <NavLink activeClassName="header-active" className='header-page-item' to={{pathname: "/schedule", state: { displayName: this.props.displayName}}}>Schedule</NavLink> :
                        false 
                        }
                        <NavLink activeClassName="header-active" className='header-page-item' to={{pathname: "/documents", state: { displayName: this.props.displayName}}}>Documents</NavLink>
                        {this.props.displayName === "Admin"? 
                        <NavLink activeClassName="header-active" className='header-page-item' to={{pathname: "/addUser", state: { displayName: this.props.displayName}}}>Add User</NavLink> :
                        false
                        }
                    </nav>
                </div>
                <div className='header-button'> 
                    <Button className='header-logout'  onClick={() => app.auth().signOut()}>Log Out</Button>
                </div>
            </div>
    )};
}
  
export default Header;