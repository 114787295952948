import React, { Component } from 'react';
import './document-page.styles.css';
import '../components.styles.css';
import app from '../../firebase/firebase.utils';
import Header from '../header/header.component';
import LoadingPage from '../loading-page/loading-page.component';
import { AuthContext } from '../../Auth';
import {Table, Button, Modal, Form, Spinner} from 'react-bootstrap';

//add upload for specific users

class DocumentPage extends Component {
    static contextType = AuthContext;

    constructor() {
        super();

        this.state = {
            categories: [],
            fileRef: {},
            dispNames: [],
            loaded: false,

            showAddFileModal: false,
            fileCategory: '',
            fileCompany: '',
            fileSelected: null,
            addFileSpinner: false,

            showDeleteFileModal: false,
            deleteItem: {},
            deleteCompany: '',
            deleteFileSpinner: false,
        }
    };

    async componentDidMount() {
        var dispName = [];
        if (this.props.location.state.displayName === "Admin") {
            this.setState({
                isAdmin: true,
            });
            //add all company names
            await app.database().ref('users/').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var keys = Object.keys(snapshot.val());
                    for (var i = 0; i < keys.length; i++) {
                        dispName.push(snapshot.val()[keys[i]].name)
                    }
                }
            });
        } else {
            dispName = [this.props.location.state.displayName];
        }
        this.setState({
            dispNames: dispName,
        });

        await app.database().ref('files/').once('value', snapshot => {
            for (var i = 0; i < dispName.length; i++) {
                if (this.state.fileRef[dispName[i]] === undefined) {
                    const fileRef = this.state.fileRef;
                    fileRef[dispName[i]] = {};
                    this.setState(fileRef);
                }
                if (snapshot.val() !== null) {
                    if (snapshot.val()[dispName[i]] !== undefined) {
                        if (snapshot.val()[dispName[i]]['Documents'] !== undefined) {
                            var categoriesKeys = Object.keys(snapshot.val()[dispName[i]]['Documents']);
                            for (var j = 0; j < categoriesKeys.length; j++) {
                                if (this.state.categories.indexOf(categoriesKeys[j]) < 0) {
                                    const categories = this.state.categories;
                                    categories.push(categoriesKeys[j]);
                                    this.setState(categories);
                                }
                                const fileRef = this.state.fileRef;
                                fileRef[dispName[i]][categoriesKeys[j]] = [];
                                this.setState(fileRef);
                                if (snapshot.val()[dispName[i]]['Documents'][categoriesKeys[j]] !== undefined) {
                                    var keys = Object.keys(snapshot.val()[dispName[i]]['Documents'][categoriesKeys[j]]);
                                    for (var k = 0; k < keys.length; k++){
                                        const fileRef = this.state.fileRef;
                                        fileRef[dispName[i]][categoriesKeys[j]].push(snapshot.val()[dispName[i]]['Documents'][categoriesKeys[j]][keys[k]]);
                                        this.setState(fileRef);
                                    }
                                }
                            }
                        }
                    }                        
                }        
            }
        });

        if (this.state.categories.length > 0) {
            this.setState({
                fileCategory: this.state.categories[0]
            })
        } else {
            this.setState({
                isOther: true
            })
        }

        if (this.state.isAdmin) {
            this.setState({
                fileCompany: this.state.dispNames[0]
            })
        }

        this.setState({ 
            loaded: true,
        });
    }

    deleteFile (){
        this.setState({
            deleteFileSpinner: true
        });

        //remove from DB
        app.database().ref('files/' + this.state.deleteCompany + '/Documents/' + this.state.deleteItem.category + "/" + this.state.deleteItem.name.replace("/","").replace("$","").replace("[","").replace("]","") + "/").remove();
            
        //remove from storage
        var deleteRef = app.storage().ref().child('/' + this.state.deleteCompany + '/Documents/' + this.state.deleteItem.category + "/" + this.state.deleteItem.name.replace("/","").replace("$","").replace("[","").replace("]",""));
        deleteRef.delete().then(function() {
            // File deleted successfully
            }).catch(function(error) {
            // Uh-oh, an error occurred!
        });

        //remove from local
        const fileRef = this.state.fileRef;
        const name = this.state.deleteItem.name;
        fileRef[this.state.deleteCompany][this.state.deleteItem.category] = this.state.fileRef[this.state.deleteCompany][this.state.deleteItem.category].filter(function(file) {
            return file.name !== name;
        });
        if (fileRef[this.state.deleteCompany][this.state.deleteItem.category].length === 0) {
            delete fileRef[this.state.deleteCompany][this.state.deleteItem.category]
        }
        this.setState(fileRef);

        this.setState({
            deleteFileSpinner: false,
            showDeleteFileModal: false
        });
    }

    handleFileCompanyChange(event) {
        this.setState({
            fileCompany: event.target.value,
        });
    }

    handleFileCategoryChange(event) {
        if (event.target.value === 'Other') {
            this.setState({
                isOther: true
            })
        } else {
            this.setState({
                isOther: false
            })
        }
        this.setState({
            fileCategory: event.target.value,
        });
    }

    handleNewFileCategory(event) {
        this.setState({
            fileCategory: event.target.value,
        });
    }

    handleFileUpload(event) {
        this.setState({
            fileSelected: event.target.files[0]
        });
    }

    showAddFileModal () {
        this.setState({
          showAddFileModal: !this.state.showAddFileModal
        });
    }

    showDeleteFileModal () {
        this.setState({
          showDeleteFileModal: !this.state.showDeleteFileModal
        });
    }

    showInitialDeleteFileModal (item, company) {
        this.setState({
          showDeleteFileModal: !this.state.showDeleteFileModal,
          deleteItem: item,
          deleteCompany: company
        });
    }

    async uploadFile() {
        if (this.state.fileCategory !== "" && this.state.fileSelected !== null) {
            this.setState({
                addFileSpinner: true,
            })

            //upload file
            var ref = this.state.fileCompany + "/Documents/" + this.state.fileCategory + "/" + this.state.fileSelected.name.split(".")[0].replace("/","").replace("$","").replace("[","").replace("]","");
            var fileRef = app.storage().ref().child(ref);
            var file = this.state.fileSelected;

            //modal for successfull file upload
            await fileRef.put(file).then(function(snapshot) {
                console.log('Uploaded a file!');
            });

            //get download URL
            var downloadURL = '';
            await fileRef.getDownloadURL().then(url => {
                downloadURL = url;
                console.log(url);
            })

            //update DB
            app.database().ref('files/' + ref).set({
                name: this.state.fileSelected.name.split(".")[0],
                downloadURL: downloadURL,
                category: this.state.fileCategory
            });

            var fileObj = {category: this.state.fileCategory, downloadURL: downloadURL, name: this.state.fileSelected.name.split(".")[0]};
            
            //update Local
            if (this.state.fileRef[this.state.fileCompany] === undefined) {
                const fileRef = this.state.fileRef;
                fileRef[this.state.fileCompany] = {}; 
                this.setState(fileRef);  
            }
            if (this.state.fileRef[this.state.fileCompany][this.state.fileCategory] === undefined) {
                const fileRef = this.state.fileRef;
                fileRef[this.state.fileCompany][this.state.fileCategory] = [];
                this.setState(fileRef);
            }
            fileRef = this.state.fileRef;
            fileRef[this.state.fileCompany][this.state.fileCategory].push(fileObj);
            this.setState(fileRef);

            if (this.state.categories.indexOf(this.state.fileCategory) < 0) {
                const categories = this.state.categories;
                categories.push(this.state.fileCategory);
                this.setState(categories);
                if (this.state.categories.length > 0) {
                    this.setState({
                        fileCategory: this.state.categories[0],
                    })
                }
            }
            
            this.setState({
                addFileSpinner: false,
                isOther: false,
                showAddFileModal: !this.state.showAddFileModal
            });
        }
    }
    
    content () {
        return (
            <div className='inventory-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container'>
                    <Modal show={this.state.showAddFileModal} onHide={this.showAddFileModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add File</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group>
                                    <Form.File>
                                        <Form.File.Label>Upload File</Form.File.Label>
                                        <Form.File.Input onChange={this.handleFileUpload.bind(this)} />
                                    </Form.File>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>File Company:</Form.Label>
                                    <Form.Control name="fileCompany" as="select" onChange={this.handleFileCompanyChange.bind(this)}>
                                        {this.state.dispNames.map(dispName => (
                                            <option value={dispName} key={dispName}>{dispName}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>File Category:</Form.Label>
                                    <Form.Control name="fileCategory" as="select" onChange={this.handleFileCategoryChange.bind(this)}>
                                        {this.state.categories.map(file => (
                                            <option key={file} value={file}>{file}</option>
                                        ))}
                                        <option key='Other' value="Other">Other</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group style={this.state.isOther? {display: "block"} : {display: "none"}} onChange={this.handleNewFileCategory.bind(this)}>
                                    <Form.Label>Category Name:</Form.Label>
                                    <Form.Control name="fileCategory" type="text" />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showAddFileModal.bind(this)}>Cancel</Button>
                            {this.state.addFileSpinner? 
                            <Button variant="primary"><Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button variant="primary" onClick={this.uploadFile.bind(this)}>Add File</Button>}
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showDeleteFileModal} onHide={this.showDeleteFileModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete File</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete {this.state.deleteItem.name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showDeleteFileModal.bind(this)}>Cancel</Button>
                            {this.state.deleteFileSpinner? 
                            <Button variant="danger"><Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button variant="danger" onClick={this.deleteFile.bind(this)}>Delete File</Button>}
                        </Modal.Footer>
                    </Modal>

                    <div className='sticky-table-title-document-page'>
                        <h4 className='document-title'>Documents</h4> 
                        {this.state.isAdmin ? 
                        <Button variant='secondary' className='add-file' onClick={this.showAddFileModal.bind(this)}>Add File</Button>
                        : false}
                    </div>

                    <Table hover size='sm'>
                        <thead>
                            <tr>
                                {this.state.isAdmin? <th className='sticky-table-header-document-page'>Company</th> : false}
                                <th className='sticky-table-header-document-page'>Category</th>
                                <th className='sticky-table-header-document-page'>File Name</th>
                                {this.state.isAdmin? <th className='sticky-table-header-document-page'>Delete?</th> : false}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.fileRef).map(company => (
                                Object.keys(this.state.fileRef[company]).map(category => (
                                    <React.Fragment key ={category}>
                                        <tr>
                                            {this.state.isAdmin? <td className='sticky-category-document-page'>{company}</td>: false}
                                            <td className='sticky-category-document-page'>{category}</td>
                                        </tr>
                                        {this.state.fileRef[company][category].map(item => (
                                            <tr key={item.name}>
                                                {this.state.isAdmin? <td></td> : false}
                                                <td></td>
                                                <td><a href={item.downloadURL} target="_blank" rel="noopener noreferrer">{item.name}</a></td>
                                                {this.state.isAdmin? <td><Button variant='danger' onClick={this.showInitialDeleteFileModal.bind(this, item, company)}>Delete</Button></td> : false}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    render () {
        return (
            <div className='inventory-page'>
                {this.state.loaded ? this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}
  
export default DocumentPage;