import React, { Component } from 'react';
import './home-page.styles.css';
import '../components.styles.css'
import app from 'firebase';
import {Link} from 'react-router-dom';
import { AuthContext } from '../../Auth';
import { Button, Table } from 'react-bootstrap';
import Logo from '../../logos/logo_v1.png';


class HomePage extends Component {
    static contextType = AuthContext;

    constructor() {
        super();
        var currentDate = new Date();
        var day = currentDate.getDay(),
        diff = currentDate.getDate() - day;
        currentDate = new Date(currentDate.setDate(diff))
        this.state = {
            date: currentDate,
            scheduleData: [],
            displayName: "",
            announcement: "Have a great day!",
            isAdmin: false,
        }
    };

    async componentDidMount() {
        var dispName;
        const user = this.context.currentUser;
        if (user != null) {
            user.providerData.forEach((profile) => {
                this.setState({
                    displayName: profile.displayName,
                });
                if (profile.displayName === "Admin") {
                    this.setState({
                        isAdmin: true
                    })
                }
                dispName = profile.displayName
            });
        }

        await app.database().ref('schedule/').once('value', snapshot => {
            if (snapshot.val() && snapshot.val()[dispName] && snapshot.val()[dispName]['pastSchedule'] && snapshot.val()[dispName]['pastSchedule'][this.state.date.getFullYear()] && snapshot.val()[dispName]['pastSchedule'][this.state.date.getFullYear()][this.state.date.getMonth() + 1] && snapshot.val()[dispName]['pastSchedule'][this.state.date.getFullYear()][this.state.date.getMonth() + 1][this.state.date.getDate()]) {
                this.setState({
                    scheduleData: snapshot.val()[dispName]['pastSchedule'][this.state.date.getFullYear()][this.state.date.getMonth() + 1][this.state.date.getDate()]
                })
            }
        });

        this.setState({ 
            loaded: true,
        });
    }

    tConvert (time) {
        var hours = parseInt(time.split(":")[0])
        var minutes = time.split(":")[1]
        var timeOfDay = hours < 12 ? 'AM' : 'PM';
        hours = hours % 12 || 12;
        return hours + ":" + minutes + " " + timeOfDay
    }

    render () {
        return (
            <div className='home-page'> 
                <div className='header'>
                    <h4 className='header-greeting'>Hello {this.state.displayName}</h4>
                    <Button className='header-logout' variant='primary' onClick={() => app.auth().signOut()}>Log Out</Button>
                </div>
                <div className='container home-page-container'>
                    <Link className='home-page-item' to={{pathname: "/inventory", state: { displayName: this.state.displayName}}}>Inventory</Link>
                    {this.state.isAdmin? 
                    <Link className='home-page-item' to={{pathname: "/reconciliation", state: { displayName: this.state.displayName}}}>Reconciliation</Link> :
                    <Link className='home-page-item' to={{pathname: "/reconciliation/new", state: { displayName: this.state.displayName}}}>Reconciliation</Link>
                    }
                    {this.state.isAdmin? 
                    <Link className='home-page-item' to={{pathname: "/schedule", state: { displayName: this.state.displayName}}}>Schedule</Link> :
                    false
                    }
                    <Link className='home-page-item' to={{pathname: "/documents", state: { displayName: this.state.displayName}}}>Documents</Link>
                    {this.state.isAdmin? 
                    <Link className='home-page-item' to={{pathname: "/addUser", state: { displayName: this.state.displayName}}}>Add User</Link> :
                    false
                    }
                </div>
                <hr></hr>
                {this.state.loaded?
                    <div className="schedule-table">
                        <h6>This Week's Schedule</h6>
                        {this.state.scheduleData.length > 0? 
                            <div>
                            <Table className="thick-border">
                                <thead>
                                    <tr>
                                    {this.state.scheduleData.map(function(day, dayIdx, ) {
                                        if (dayIdx < 7) {
                                            return (
                                                <th key={dayIdx} className="thick-border">{day[0]}</th> 
                                            )
                                        } else {
                                            return (
                                                false
                                            )
                                        }
                                    }, this)}
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {this.state.scheduleData.map(function(day, dayIdx, ) {
                                        if (dayIdx < 7) {
                                            return (
                                                <td key={dayIdx} className="thick-border">
                                                    <Table className="day-table">
                                                    <tbody>
                                                        {day[1].map(function(shift, shiftIdx, ) {
                                                            return (
                                                            
                                                            <tr key={shiftIdx}>
                                                                <td>
                                                                    {this.tConvert(shift[0])} 
                                                                        <br></br> 
                                                                    {this.tConvert(shift[1])}
                                                                </td>
                                                                <td className="employee-name">{shift[2]}</td>
                                                            </tr>
                                                        )}, this)}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            )
                                        } else {
                                            return (
                                                false
                                            )
                                        }
                                    }, this)}
                                </tr>
                                </tbody>
                            </Table>
                            </div>
                            : 
                            <div>
                                <em>No schedule found.</em>
                            </div>
                        } 
                    </div>
                    : false  
                    }
                    <hr></hr>
                <div className='home-page-footer'>
                    <h5 className='home-page-item'>{this.state.announcement}</h5>
                    <img className='home-page-logo' src={Logo} alt="Logo" />
                </div>
            </div>
        )
    };
}
  
export default HomePage;