import React, { Component } from 'react';
import './past-order-page.styles.css';
import '../../components.styles.css'
import app from '../../../firebase/firebase.utils';
import Header from '../../header/header.component';
import LoadingPage from '../../loading-page/loading-page.component';
import {Table, Button, Modal, Spinner} from 'react-bootstrap';

class PastOrderPage extends Component {
    constructor() {
        super();

        this.state = {
            loaded: false,
            pastOrders: {},

            showDeleteFileModal: false,
            deleteItem: {},
            deleteCompany: '',
            deleteFileSpinner: false,
        }
    };

    async componentDidMount(){
        var dispName = [];
        if (this.props.location.state.displayName === "Admin") {
            this.setState({
                isAdmin: true,
            });
            
            //add all company display names
            await app.database().ref('users/').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var keys = Object.keys(snapshot.val());
                    for (var i = 0; i < keys.length; i++) {
                        dispName.push(snapshot.val()[keys[i]].name)
                    }
                }
            });
        } else {
            dispName = [this.props.location.state.displayName];
        }

        await app.database().ref('files/').once('value', snapshot => {
            for (var i = 0; i < dispName.length; i++) {
                if (snapshot.val() !== null) {
                    if (snapshot.val()[dispName[i]] !== undefined) {
                        if (snapshot.val()[dispName[i]]["Orders"] !== undefined) { 
                            var keys = Object.keys(snapshot.val()[dispName[i]]["Orders"]);
                            for (var k = 0; k < keys.length; k++) {
                                if (this.state.pastOrders[dispName[i]] === undefined) {
                                    const pastOrders = this.state.pastOrders;
                                    pastOrders[dispName[i]] = {};
                                    pastOrders[dispName[i]]["Orders"] = [snapshot.val()[dispName[i]]["Orders"][keys[k]]];
                                    this.setState(pastOrders);
                                } else {
                                    const pastOrders = this.state.pastOrders;
                                    pastOrders[dispName[i]]["Orders"].push(snapshot.val()[dispName[i]]["Orders"][keys[k]]);
                                    this.setState(pastOrders);
                                }
                            }
                        }
                    }
                }
            }
        });
        this.setState({ 
            loaded: true,
        });
    }

    showDeleteFileModal () {
        this.setState({
          showDeleteFileModal: !this.state.showDeleteFileModal
        });
    }

    showInitialDeleteFileModal (item, company) {
        this.setState({
          showDeleteFileModal: !this.state.showDeleteFileModal,
          deleteItem: item,
          deleteCompany: company
        });
    }

    async deleteFile (){
        this.setState({
            deleteFileSpinner: true
        });

        //remove from DB
        var date = this.state.deleteItem.dateCreated.split("-");
        date[2] = date[2].split(" ")[0]
        await app.database().ref('files/' + this.state.deleteCompany + '/' + this.state.deleteItem.category + "/" + this.state.deleteItem.name).remove();
        await app.database().ref('orders/pastOrders/' + date[2] + "/" + date[0] + "/" + date[1] + "/" + this.state.deleteItem.name).remove();

        //remove from storage
        var deleteRef = app.storage().ref().child('/' + this.state.deleteCompany + '/' + this.state.deleteItem.category + "/" + date[2] + "/" + date[0] + "/" + date[1] + "/" + this.state.deleteItem.name);
        await deleteRef.delete().then(function() {
            // File deleted successfully
            }).catch(function(error) {
            // Uh-oh, an error occurred!
        });

        //remove from local
        const pastOrders = this.state.pastOrders;
        var a = this.state.deleteItem.name;
        pastOrders[this.state.deleteCompany][this.state.deleteItem.category] = this.state.pastOrders[this.state.deleteCompany][this.state.deleteItem.category].filter(function(file) {
            return file.name !== a;
        });
        if (pastOrders[this.state.deleteCompany][this.state.deleteItem.category].length === 0) {
            delete pastOrders[this.state.deleteCompany]
        }
        this.setState(pastOrders);
        

        this.setState({
            deleteFileSpinner: false,
            showDeleteFileModal: false
        });
    }

    handleOrder (item, company) {
        var a = !item.ordered;

        var updates = {};
        updates['files/' + company + "/Orders/" + item.name + "/ordered"] = a;
        app.database().ref().update(updates);
        
        const pastOrders = this.state.pastOrders;
        let idx = pastOrders[company][item.category].findIndex((obj => obj.name === item.name));
        pastOrders[company][item.category][idx].ordered = a;
        this.setState(pastOrders)
    }
    
    content () {
        return (
            <div className='past-order-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container'>
                    <Modal show={this.state.showDeleteFileModal} onHide={this.showDeleteFileModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete File</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete {this.state.deleteItem.name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showDeleteFileModal.bind(this)}>Cancel</Button>
                            {this.state.deleteFileSpinner? 
                            <Button variant="danger"><Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button variant="danger" onClick={this.deleteFile.bind(this)}>Delete File</Button>}
                        </Modal.Footer>
                    </Modal>

                    <h4 className='sticky-table-title-past-orders past-order-title'>Past Inventory Orders</h4>
                    <Table hover size='sm'>
                        <thead>
                            <tr>
                                {this.state.isAdmin? <th className='sticky-table-header-past-orders'>Company</th> : false}
                                <th className='sticky-table-header-past-orders'>Order ID</th>
                                <th className='sticky-table-header-past-orders'>Date Created</th>
                                {this.state.isAdmin? <th className='sticky-table-header-past-orders'>Ordered?</th> : false}
                                <th className='sticky-table-header-past-orders'>Delete?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.pastOrders).map(company => (
                                Object.keys(this.state.pastOrders[company]).map(file => (
                                    <React.Fragment key={file}>
                                        {this.state.isAdmin?
                                        <tr>
                                            <td className='sticky-category-past-orders'>{company}</td> 
                                        </tr>
                                        : false}
                                        {this.state.pastOrders[company][file].map(item => (
                                            <tr key={item.name}>
                                                {this.state.isAdmin? <td></td> : false}
                                                <td><a href={item.downloadURL} target="_blank" rel="noopener noreferrer">{item.name}</a></td>
                                                <td>{item.dateCreated}</td>
                                                {this.state.isAdmin? item.ordered? <td><input type="checkbox" id="ordered" name="ordered" onChange={this.handleOrder.bind(this, item, company)} checked></input></td> : <td><input type="checkbox" id="ordered" name="ordered" onChange={this.handleOrder.bind(this, item, company)}></input></td> : false}
                                                {!item.ordered? <td><Button variant='danger' onClick={this.showInitialDeleteFileModal.bind(this, item, company)}>Delete</Button></td> : <td>ORDERED</td>}
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() 
                :  <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}
  
export default PastOrderPage;