import React, { Component } from 'react';
import OrderSummaryItem from './order-summary-item/order-summary-item.component';
import app from '../../../firebase/firebase.utils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {Table, Spinner, Button} from 'react-bootstrap';
import Header from '../../header/header.component';
import './order-summary-page.styles.css'
import '../../components.styles.css';

class OrderSummaryPage extends Component {
    constructor(props) {
        super(props);

        var currentdate = new Date();
        this.state = {
            month: currentdate.getMonth()+1,
            date: currentdate.getDate(),
            year: currentdate.getFullYear(),
            datetime: (currentdate.getMonth()+1) + "-" + currentdate.getDate() + "-" + currentdate.getFullYear() + " @ " + currentdate.toLocaleTimeString(),
            loaded: false,
        }
    };

    async componentDidMount () {
        var lastCategory = '';
        var counter = 0;
        var pageNum = 1;
        var rows = 32;

        jsPDF.autoTableSetDefaults({
            headStyles: { 
                fillColor: [255, 255, 255],
                textColor: 0,
            },
            tableLineColor: 0,
            tableLineWidth: 1,
            styles: {
                lineColor: 0,
                lineWidth: 1,
            },
            bodyStyles: {
                textColor: 0,
            },
            didDrawPage: function (data) {
                doc.setFontSize("10");
                if (data.table.body[(pageNum-1)*rows] !== undefined) {
                    if (data.table.body[(pageNum-1)*rows].raw[0].content === '') {
                        doc.text(lastCategory, 45, 76);
                    }
                }
                for (var i = counter; i < data.table.body.length; i++) {
                    if (i > (pageNum*rows)) {
                        break;
                    } else {
                        if (data.table.body[i].raw[0].content !== "") {
                            lastCategory = data.table.body[i].raw[0].content;
                            counter = i + 1
                            break;
                        }
                    }
                }
                pageNum = pageNum + 1;
            },
        })

        //output jspdf into blob
        var doc = new jsPDF('p', 'pt', 'letter');
        doc.text('Order created by ' + this.props.location.state.displayName + ' on ' + this.state.datetime, 15, 20)
        doc.autoTable({ 
            html: '#summary-table', 
            theme: 'grid',
        });
        var file = doc.output('blob');

        //get currentID
        var currentID = null;
        await app.database().ref('orders/currentID').once('value', snapshot => {
            currentID = snapshot.val();
        });
        
        //upload file
        var storageRef = app.storage().ref().child('/' + this.props.location.state.displayName + "/Orders/" +this.state.year + "/" + this.state.month + "/" + this.state.date + "/o" + currentID);
        await storageRef.put(file).then(function(snapshot) {
            console.log('Uploaded file!');
        });

        //get download url
        var downloadURL = '';
        await storageRef.getDownloadURL().then(url => {
            downloadURL = url;
        })
        
        //update db
        app.database().ref('files/' + this.props.location.state.displayName + "/Orders/o" + currentID).set({
            name: "o" + currentID,
            dateCreated: this.state.datetime,
            downloadURL: downloadURL,
            category: "Orders",
            ordered: false,
        });

        await app.database().ref('orders/pastOrders/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/o" + currentID).set({
            store: this.props.location.state.displayName
        });

        var orderItem = Object.keys(this.props.location.state.orderList);
        for (var i = 0; i < orderItem.length; i++) {
            await app.database().ref('orders/pastOrders/' + this.state.year + "/" + this.state.month + "/" + this.state.date + "/o" + currentID + "/orderList/" + orderItem[i]).set({
                name: this.props.location.state.orderList[orderItem[i]].name,
                category: this.props.location.state.orderList[orderItem[i]].category,
                key: this.props.location.state.orderList[orderItem[i]].key,
                quantity: this.props.location.state.orderList[orderItem[i]].quantity,
            });
        }
        
        //add to currentID
        var updates = {};
        updates['/orders/currentID'] = currentID+1;
        await app.database().ref().update(updates);

        this.setState({
            loaded: true
        })
    }

    render () {
        return (
            <div>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container container-with-footer'>
                    <div className='order-summary-page' id='summary-page'>
                        <h4 id='summary-table-header'>Order created by {this.props.location.state.displayName} on {this.state.datetime}</h4>
                        <Table bordered size='sm' id='summary-table'>
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(this.props.location.state.order).map(category => (
                                <React.Fragment>
                                    {this.props.location.state.order[category].map(function(item, idx, ) {
                                        if (idx > 0) {
                                            return (
                                                <OrderSummaryItem item={item}></OrderSummaryItem>
                                            )
                                        } else {
                                            return (
                                                <tr>
                                                    <td>{category}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.quantity}</td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </React.Fragment>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className='sticky-footer'>
                            {this.state.loaded? <Button className='float-right' variant="success">Submitted</Button> :
                            <Button className='float-right' variant="warning">Uploading...<Spinner animation="border" role="status" size="sm"></Spinner></Button>}
                    </div>
                </div>
            </div>
        )
    };
}
  
export default OrderSummaryPage;