import React, { Component } from 'react';
import app from '../../../firebase/firebase.utils';
import Header from '../../header/header.component';
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import LoadingPage from '../../loading-page/loading-page.component';
import ReconciliationContainer from '../reconciliation-container/reconciliation-container.component';


class EditReconciliationPage extends Component {
    constructor(props) {
        super(props);
        var currentdate = new Date();
        this.state = {
            datetime: (currentdate.getMonth()+1) + "-"
            + currentdate.getDate()  + "-" 
            + currentdate.getFullYear(),
            loaded: false,
            gas: [],
            inputs: [],
            reconciliationList: {},
            reconciliation: []
        }
    };

    async componentDidMount() {
        await app.database().ref('reconciliationData/' + this.props.location.state.store + '/gasData/gas').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var gas = Object.keys(snapshot.val());
                for (var i = 0; i < gas.length; i++) {
                    this.state.gas.push(snapshot.val()[gas[i]])
                }
            }
        });

        if (this.props.location.state.reconciliationList !== undefined) {
            this.setState({
                reconciliationList: this.props.location.state.reconciliationList
            })
        } else {
            await app.database().ref('reconciliationData/general/gasData/gasTableInputs').once('value', snapshot => {
                var k = 0;
                if (snapshot.val() !== null) {
                    var tables = Object.keys(snapshot.val());
                    for (var i = 0; i < tables.length; i++) {
                        var inputs = Object.keys(snapshot.val()[tables[i]]);
                        var counter = 1;
                        for (var j = 0; j < inputs.length; j++) {
                            const reconciliationList = this.state.reconciliationList;
                            if (j === 0) {
                                reconciliationList[snapshot.val()[tables[i]][inputs[j]]] = {}
                                var heading = snapshot.val()[tables[i]][inputs[j]];
                            } else if (j === 1) {
                                reconciliationList[heading]['info'] = snapshot.val()[tables[i]][inputs[j]];
                            } else {
                                reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]] = []
                                if (heading !== 'Inventory') {
                                    for (k = 0; k < this.state.gas.length; k++) {
                                        reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]].push({
                                            name: this.state.gas[k],
                                            quantity: this.props.location.state.pastReconciliation[snapshot.val()[tables[i]].heading][snapshot.val()[tables[i]].subheading][this.state.gas[k]]
                                        })
                                    }
                                } else {
                                    for (k = 0; k < this.state.gas.length-1; k++) {
                                        if (snapshot.val()[tables[i]] !== undefined && snapshot.val()[tables[i]][inputs[j]] !== undefined) {
                                            if (this.props.location.state.pastReconciliation[snapshot.val()[tables[i]].heading] && this.props.location.state.pastReconciliation[snapshot.val()[tables[i]].heading][snapshot.val()[tables[i]]['subheading' + counter]]) {
                                                reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]].push({
                                                    name: this.state.gas[k],
                                                    quantity: this.props.location.state.pastReconciliation[snapshot.val()[tables[i]].heading][snapshot.val()[tables[i]]['subheading' + counter]][this.state.gas[k]]
                                                })
                                            } else {
                                                reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]].push({
                                                    name: this.state.gas[k],
                                                    quantity: 0
                                                })
                                            }
                                        }
                                    }
                                    counter = counter + 1;
                                }
                            }
                            this.setState(reconciliationList);
                        }
                    }
                }
            });
        }
        console.log(this.state.reconciliationList)
        this.setState({
            loaded: true
        })
    }

    handleChange = (heading, subheading, item, quantity) => {
        for (var i = 0; i < this.state.reconciliationList[heading][subheading].length; i++) {
            if (this.state.reconciliationList[heading][subheading][i].name === item.name){
                const reconciliationList = this.state.reconciliationList;
                var a = parseFloat(quantity);
                if (a > 0) {
                    reconciliationList[heading][subheading][i].quantity = a;
                } else {
                    reconciliationList[heading][subheading][i].quantity = 0;
                }
                this.setState(reconciliationList);
                break;
            }
        }
    }

    content () {
        return (
            <div className='reconciliation-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                {this.state.submitSpinner? <div>Loading...</div> :
                <div className='container container-with-footer'>
                    <div>
                        Reconciliation for: {this.props.location.state.store}
                    </div>
                    <div>
                        Date: {this.props.location.state.date[0] + '-' + this.props.location.state.date[1] + '-' + this.props.location.state.date[2]}
                    </div>
                    <div className='row'>
                        {Object.keys(this.state.reconciliationList).map(function(inputGroup, inpGrpIdx, ) {
                            return (
                                Object.keys(this.state.reconciliationList[inputGroup]).map(function(inputItem, idx,) {
                                    if (idx < 2) {
                                        if (inputItem !== 'info') {
                                            return (
                                                <ReconciliationContainer styling='firstColumn threeColumn' heading={inputGroup} info={this.state.reconciliationList[inputGroup].info} subheading={inputItem} key={inputItem} data={this.state.reconciliationList[inputGroup][inputItem]} handleChange={this.handleChange}></ReconciliationContainer>
                                            )
                                        } else {
                                            return false
                                        }
                                    } else if (idx === 2) {
                                        if (inputItem !== 'info') {
                                            return (
                                                <React.Fragment>
                                                    <h3 className='threeColumn'>Delivery</h3>
                                                    <ReconciliationContainer styling='threeColumn' heading={inputGroup} info={this.state.reconciliationList[inputGroup].info} subheading={inputItem} key={inputItem} data={this.state.reconciliationList[inputGroup][inputItem]} handleChange={this.handleChange}></ReconciliationContainer>
                                                </React.Fragment>
                                                
                                            )
                                        } else {
                                            return false
                                        }
                                    } else if (idx === 3) {
                                        if (inputItem !== 'info') {
                                            return (
                                                <ReconciliationContainer styling='threeColumn' heading={inputGroup} info="Please enter $ amount" subheading={inputItem} key={inputItem} data={this.state.reconciliationList[inputGroup][inputItem]} handleChange={this.handleChange}></ReconciliationContainer>
                                            )
                                        } else {
                                            return false
                                        }
                                    } else {
                                        return false
                                    }
                            }, this))
                        }, this)}
                    </div>
                    <div className='sticky-footer'>
                    <Link to={{pathname: "/reconciliation/summary", state: { reconciliationList: this.state.reconciliationList, displayName: this.props.location.state.displayName, store: this.props.location.state.store, date: this.props.location.state.date, name: this.props.location.state.name }}}>
                        <Button className='float-right'>Submit</Button>
                    </Link>
                    </div>
                </div>
                }
            </div>
            
        )
    }

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}

export default EditReconciliationPage;