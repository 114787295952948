import React, { Component } from 'react';

class OrderSummaryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    render () {
        return (
            <tr>
                <td></td>
                <td>{this.props.item.name}</td>
                <td>{this.props.item.quantity}</td>
            </tr>
    )};
}
  
export default OrderSummaryItem;