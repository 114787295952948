import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import LoginPage from './components/login-page/login-page.component';
import HomePage from './components/home-page/home-page.component';
import InventoryMenu from './components/inventory-page/inventory-menu/inventory-menu.component';
import NewOrderPage from './components/inventory-page/new-order-page/new-order-page.component';
import PastOrderPage from './components/inventory-page/past-order-page/past-order-page.component';
import ReconciliationPage from './components/reconciliation-page/reconciliation-page.component';
import ReconciliationSummaryPage from './components/reconciliation-page/reconciliation-summary-page/reconciliation-summary-page.component';
import ReconciliationMonthlySummaryPage from './components/reconciliation-page/monthly-summary-reconciliation-page/monthly-summary-reconciliation-page.component'
import PastReconciliationPage from './components/reconciliation-page/past-reconciliation-page/past-reconciliation-page.component'
import ReconciliationMenu from './components/reconciliation-page/reconciliation-menu/reconciliation-menu.component'
import EditReconciliationPage from './components/reconciliation-page/edit-reconciliation-page/edit-reconciliation-page.component'
import MonthlyReconciliationPage from './components/reconciliation-page/monthly-reconciliation-page/monthly-reconciliation-page.component'
import ResetReconciliationPage from './components/reconciliation-page/reset-reconciliation-page/reset-reconciliation-page.component'

import OrderSummaryPage from './components/inventory-page/order-summary-page/order-summary-page.component';
import DocumentPage from './components/document-page/document-page.component';
import AddUserPage from './components/addUser-page/addUser-page.component';
import SchedulePage from './components/schedule-page/schedule-page.component';
import ScheduleMenu from './components/schedule-page/schedule-menu/schedule-menu.components';
import PastSchedulePage from './components/schedule-page/past-schedule-page/past-schedule-page.component';
import EmployeeManager from './components/schedule-page/manage-employee-page/manage-employee-page.component';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Route exact path='/login' component={LoginPage}></Route>
          <PrivateRoute exact path='/' component={HomePage}></PrivateRoute>
          <PrivateRoute exact path='/inventory' component={InventoryMenu}></PrivateRoute>
          <PrivateRoute exact path='/inventory/new' component={NewOrderPage}></PrivateRoute>
          <PrivateRoute exact path='/inventory/past' component={PastOrderPage}></PrivateRoute>
          <PrivateRoute exact path='/inventory/summary' component={OrderSummaryPage}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation/new' component={ReconciliationPage}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation/monthSummary' component={ReconciliationMonthlySummaryPage}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation/reset' component={ResetReconciliationPage}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation' component={ReconciliationMenu}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation/summary' component={ReconciliationSummaryPage}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation/past' component={PastReconciliationPage}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation/edit' component={EditReconciliationPage}></PrivateRoute>
          <PrivateRoute exact path='/reconciliation/monthly' component={MonthlyReconciliationPage}></PrivateRoute>
          <PrivateRoute exact path='/documents' component={DocumentPage}></PrivateRoute>
          <PrivateRoute exact path='/addUser' component={AddUserPage}></PrivateRoute>
          <PrivateRoute exact path='/schedule' component={ScheduleMenu}></PrivateRoute>
          <PrivateRoute exact path='/schedule/new' component={SchedulePage}></PrivateRoute>
          <PrivateRoute exact path='/schedule/past' component={PastSchedulePage}></PrivateRoute>
          <PrivateRoute exact path='/schedule/employees' component={EmployeeManager}></PrivateRoute>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
