import React, { Component } from 'react';

class ReconciliationItem extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    };

    handleChange(event) {
        this.props.handleChange(this.props.heading, this.props.subheading, this.props.item, event.target.value);
    }

    render () {
        return (
            <div>
                <div>{this.props.name}: </div>
                {this.props.item.quantity === 0 ?  <input type="number" onChange={this.handleChange.bind(this)} /> :  <input type="number" value={this.props.item.quantity} onChange={this.handleChange.bind(this)} />}
            </div>
    )};
}
  
export default ReconciliationItem;