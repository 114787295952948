import React, { Component } from 'react';
import './reconciliation-menu.styles.css';
import Header from '../../header/header.component';
import { Link } from "react-router-dom";

class ReconciliationMenu extends Component {

    constructor() {
        super();

        this.state = {
        }
    };

    render () {
        return (
            <div className='inventory-page'> 
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container inventory-page-container'>
                    <h4>Reconciliation Menu</h4>
                    <Link className='inventory-page-item' to={{pathname: "/reconciliation/new", state: {displayName: this.props.location.state.displayName}}}>New Reconciliation</Link>
                    <Link className='inventory-page-item' to={{pathname: "/reconciliation/past", state: {displayName: this.props.location.state.displayName}}}>Past Reconciliation</Link>
                    <Link className='inventory-page-item' to={{pathname: "/reconciliation/monthly", state: {displayName: this.props.location.state.displayName}}}>Monthly Reconciliation</Link>
                    <Link className='inventory-page-item' to={{pathname: "/reconciliation/reset", state: {displayName: this.props.location.state.displayName}}}>Reset Reconciliation Data</Link>
                </div>
            </div>
        )
    };
}
  
export default ReconciliationMenu;