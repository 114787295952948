import React, { Component } from "react";
import app from '../../firebase/firebase.utils';
import firebase from 'firebase';
import Header from '../header/header.component';
import { Form, Button, Alert } from "react-bootstrap";
import './addUser-page.styles.css';
import '../components.styles.css';

class AddUserPage extends Component {
    constructor() {
        super();

        this.state = {
            name: '',
            email: '',
            password: '',
            showMessage: false,
        }
    };

    async handleSubmit () {
        this.setState({
            addUserSpinner: true
        });
        
        var a = this.state.name;


        var config = {
            apiKey: "AIzaSyCWHkKgVdK-QV9qrCf903nzlKdKP9h7KYc",
            authDomain: "basra-corp.firebaseapp.com",
            databaseURL: "https://basra-corp.firebaseio.com",
        };
        if (firebase.apps.length === 1) {
            var secondaryApp = firebase.initializeApp(config, "Secondary");
        } else {
            secondaryApp = firebase.app("Secondary")
        }
        
        try {
            await secondaryApp.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(function(result) {
                result.user.updateProfile({
                    displayName: a
                });
                secondaryApp.auth().signOut();
            });
    
            await app.database().ref('users/' + this.state.name + "/").set({
                name: this.state.name,
            });

            this.setState({
                addUserSpinner: false,
                showMessage: true,
                messageType: 'success',
                message: 'User created successfully!'
            });
            document.getElementById("add-user-form").reset();
        } catch (error) {
            console.log(error);
            this.setState({
                addUserSpinner: false,
                showMessage: true,
                messageType: 'danger',
                message: error.message
            });
            document.getElementById("add-user-form").reset();
        }
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value,
        })
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        })
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        })
    }

    show(event) {
        this.setState({
            showMessage: false
        })
    }

    render () {
        return (
            <div className='inventory-page'>
                <Header displayName="Admin"></Header>
                <div className='container inventory-page-container'>
                    <h4>Add User</h4>
                    <h5>What is the email and password for new user?</h5>
                    <Form id='add-user-form'>
                        <Form.Group>
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control name="name" onChange={this.handleNameChange.bind(this)} type="text" placeholder="Name" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control name="email" onChange={this.handleEmailChange.bind(this)} type="email" placeholder="Email" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password" onChange={this.handlePasswordChange.bind(this)} type="password" placeholder="Password" />
                        </Form.Group>
                        <Button variant='primary' className='float-right' onClick={this.handleSubmit.bind(this)}>Create User</Button>
                        {this.state.showMessage? 
                        <Alert className='alert' onClose={this.show.bind(this)} variant={this.state.messageType} dismissible>
                            {this.state.message}
                        </Alert> :
                        false
                        }

                    </Form>
                </div>
            </div>
        )
    };
}
  
export default AddUserPage;