import React, { Component } from 'react';
import app from '../../../firebase/firebase.utils';
import Header from '../../header/header.component';
import { Button, Form } from 'react-bootstrap';
import LoadingPage from '../../loading-page/loading-page.component';
import ReconciliationContainer from '../reconciliation-container/reconciliation-container.component';


class MonthlyReconciliationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            master: [],
            users: [],
            gas: [],
            reconciliationList: {},
            selectedUser: null,
            loaded: false,
        }
    };

    async componentDidMount() {
        var firstUser = null;
        await app.database().ref('users').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var users = Object.keys(snapshot.val());
                for (var i = 0; i < users.length; i++) {
                    if (snapshot.val()[users[i]].name !== 'Admin') {
                        if (i === 1) {
                            firstUser = snapshot.val()[users[i]].name;
                            this.setState({
                                selectedUser: firstUser
                            })
                        }
                        this.state.users.push(snapshot.val()[users[i]])
                    }
                    
                }
            }
        });

        var master = null;

        await app.database().ref('reconciliationData/' + firstUser + '/gasData/gas').once('value', snapshot => {
            if (snapshot.val() !== null) {
                var gas = Object.keys(snapshot.val());
                for (var i = 0; i < gas.length; i++) {
                    this.state.gas.push(snapshot.val()[gas[i]])
                }
            }
        });

        await app.database().ref('reconciliationData/' + firstUser + '/gasData/last').once('value', snapshot => {
            if (snapshot.val() !== null) {
                this.setState ({
                    master: snapshot.val()
                })
                master = snapshot.val()
            }
        });

        await app.database().ref('reconciliationData/general/gasData/gasTableInputs').once('value', snapshot => {
            var k = 0;
            if (snapshot.val() !== null) {
                var tables = Object.keys(snapshot.val());
                for (var i = 0; i < tables.length; i++) {
                    var inputs = Object.keys(snapshot.val()[tables[i]]);
                    for (var j = 0; j < inputs.length; j++) {
                        const reconciliationList = this.state.reconciliationList;
                        if (j === 0) {
                            reconciliationList[snapshot.val()[tables[i]][inputs[j]]] = {}
                            var heading = snapshot.val()[tables[i]][inputs[j]];
                        } else if (j === 1) {
                            reconciliationList[heading]['info'] = snapshot.val()[tables[i]][inputs[j]];
                        } else {
                            if (heading !== 'Inventory') {
                                reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]] = []
                                for (k = 0; k < this.state.gas.length; k++) {
                                    reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]].push({
                                        name: this.state.gas[k],
                                        quantity: master[heading][this.state.gas[k]]
                                    })
                                }
                            } else {
                                    if (snapshot.val()[tables[i]][inputs[j]] === 'Ending') {
                                        reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]] = []
                                        for (k = 0; k < this.state.gas.length-1; k++) {
                                            reconciliationList[heading][snapshot.val()[tables[i]][inputs[j]]].push({
                                                name: this.state.gas[k],
                                                quantity: master[heading][this.state.gas[k]]
                                            })
                                        }
                                    }                                
                            }
                        }
                        this.setState(reconciliationList);
                    }
                }
            }
        });

        this.setState({
            loaded: true,
        })

    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeVal = (heading, subheading, item, quantity) => {
        for (var i = 0; i < this.state.reconciliationList[heading][subheading].length; i++) {
            if (this.state.reconciliationList[heading][subheading][i].name === item.name){
                const reconciliationList = this.state.reconciliationList;
                reconciliationList[heading][subheading][i].quantity = parseFloat(quantity);
                console.log(reconciliationList)
                this.setState(reconciliationList);
                break;
            }
        }
    }

    handleSubmit (e) {
        //update db
        var a = {}
        console.log(this.state.reconciliationList)
        var tables = Object.keys(this.state.reconciliationList)
        console.log(tables)
        for (var i = 0; i < tables.length; i++) {
            a[tables[i]] = {}
            var gasTypes = this.state.reconciliationList[tables[i]]['Ending']
            for (var j = 0; j < gasTypes.length; j++) {
                a[tables[i]][gasTypes[j]['name']] = gasTypes[j]['quantity']
            }
        }
        //update db
        app.database().ref('reconciliationData/' + this.state.selectedUser + '/gasData/last').set(a);
    }

    content () {
        return (
            <div className='new-order-page'>
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container'>
                    <h4>Reset Reconciliation Data</h4>
                    <Form>
                        <Form.Group>
                            <Form.Label>Reconciliation for:  </Form.Label>
                            <Form.Control name="selectedUser" as="select" onChange={this.handleChange.bind(this)}>
                                {this.state.users.map(function(user, userIdx, ) {
                                    return (
                                    <option key={userIdx}>{user.name}</option>
                                )})}
                            </Form.Control>    
                        </Form.Group>
                    </Form>
                    {Object.keys(this.state.reconciliationList).map(function(inputGroup, inpGrpIdx, ) {
                            return (
                                Object.keys(this.state.reconciliationList[inputGroup]).map(function(inputItem, idx,) {
                                    if (inputItem !== 'info') {
                                        return (
                                            <ReconciliationContainer styling='threeColumn' heading={inputGroup} info={this.state.reconciliationList[inputGroup].info} subheading={inputItem} key={inputItem} data={this.state.reconciliationList[inputGroup][inputItem]} handleChange={this.handleChangeVal}></ReconciliationContainer>
                                    )} else {
                                        return false
                                    }
                            }, this))
                    }, this)}
                </div>
                <div className='sticky-footer'>
                    <Button className='float-right' onClick={this.handleSubmit.bind(this)}>Submit</Button>
                </div>
            </div> 
        )
    }

    render () {
        return (
            <div className='new-order-page'>
                {this.state.loaded ? 
                this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}

export default MonthlyReconciliationPage;