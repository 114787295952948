import React, { Component } from 'react';
import Header from '../../header/header.component';
import app from '../../../firebase/firebase.utils';
import {Form, Table, Button} from 'react-bootstrap';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import LoadingPage from '../../loading-page/loading-page.component';
import 'react-datepicker/dist/react-datepicker.css';
import './past-schedule-page.styles.css'

class SchedulePage extends Component {

    constructor() {
        super();
        var currentDate = new Date();
        var day = currentDate.getDay(),
        diff = currentDate.getDate() - day;
        currentDate = new Date(currentDate.setDate(diff))
        this.state = {
            date: currentDate,
            scheduleData: {},
            currentDispName: "",
            dispName: [],
        }
    };

    async componentDidMount() {
        var dispName = [];
        if (this.props.location.state.displayName === 'Admin') {
            this.setState({
                isAdmin: true,
            })
            var firstUser = null;
            await app.database().ref('users').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var users = Object.keys(snapshot.val());
                    for (var i = 0; i < users.length; i++) {
                        if (snapshot.val()[users[i]].name !== 'Admin') {
                            if (i === 1) {
                                firstUser = snapshot.val()[users[i]].name;
                                this.setState({
                                    currentDispName: firstUser,
                                    dispName: [firstUser]
                                })
                            } else {
                                this.state.dispName.push(snapshot.val()[users[i]]['name'])
                            }
                            
                            dispName.push(snapshot.val()[users[i]]['name'])
                        }
                        
                    }
                }
            });
        } else {
            this.setState({
                currentDispName: this.props.location.state.displayName,
                dispName: [this.props.location.state.displayName]
            })
            dispName = [this.props.location.state.displayName]
        }
        
        await app.database().ref('schedule/').once('value', snapshot => {
            for (var i = 0; i < dispName.length; i++) {
                if (snapshot.val() !== null) {
                    if (snapshot.val()[dispName[i]] !== undefined) {
                        if (snapshot.val()[dispName[i]]['pastSchedule'] !== undefined) {
                            const scheduleData = this.state.scheduleData;
                            scheduleData[dispName[i]] = snapshot.val()[dispName[i]]['pastSchedule'];
                            this.setState(scheduleData);
                        }
                    }
                }
            }
        });
        
        this.setState({ 
            loaded: true,
        });
    }

    handleDateChange = (date) => {
        this.setState({
            date: date
        });
    }

    isWeekday = date => {
        const day = date.getDay();
        return day === 0;
    }

    tConvert (time) {
        var hours = parseInt(time.split(":")[0])
        var minutes = time.split(":")[1]
        var timeOfDay = hours < 12 ? 'AM' : 'PM';
        hours = hours % 12 || 12;
        return hours + ":" + minutes + " " + timeOfDay
    }

    handleUserChange = (user) => {
        this.setState({
            currentDispName: user
        });
    }

    content () {
        return (
            <div className='inventory-page'> 
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='schedule-container'>
                    <h4>Past Schedules</h4>
                    {this.state.isAdmin?
 <div>
 <Form.Group>
     <Form.Label>Schedule for:  </Form.Label>
     <Form.Control as="select" onChange={this.handleUserChange.bind(this)}>
         {this.state.dispName.map(function(user, userIdx, ) {
             return (
             <option key={userIdx}>{user}</option>
         )}, this)}
     </Form.Control>     
 </Form.Group>
 <hr></hr>
 </div>
                    : false
                    }
                   
                    <div className="week-selector">
                        Select Week:&nbsp;
                        <DatePicker filterDate={this.isWeekday.bind(this)} selected={this.state.date} onChange={this.handleDateChange.bind(this)} placeholderText="Select a week"/>
                        
                        {this.state.scheduleData[this.state.currentDispName] && this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()] && this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1] && this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1][this.state.date.getDate()]? 
                        <Link to={{pathname: "/schedule/new", state: { schedule: this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1][this.state.date.getDate()], displayName: this.props.location.state.displayName, date: this.state.date, currentDispName: this.state.currentDispName, }}}>
                                <Button className='float-right' variant="warning" size="sm">Copy/Edit Schedule</Button>
                        </Link>
                        :
                        false 
                        }
                    </div>
                    
                    <div className="schedule-table">
                        {this.state.scheduleData[this.state.currentDispName] && this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()] && this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1] && this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1][this.state.date.getDate()]? 
                            <div>
                            <Table className="thick-border">
                                <thead>
                                    <tr>
                                    {this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1][this.state.date.getDate()].map(function(day, dayIdx, ) {
                                        if (dayIdx < 7) {
                                            return (
                                                <th className="thick-border" key={dayIdx}>{day[0]}</th> 
                                            )
                                        } else {
                                            return (
                                                false
                                            )
                                        }
                                    }, this)}
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1][this.state.date.getDate()].map(function(day, dayIdx, ) {
                                        if (dayIdx < 7) {
                                            return (
                                                <td key={dayIdx} className="thick-border">
                                                    <Table className="day-table">
                                                    <tbody>
                                                        {day[1].map(function(shift, shiftIdx, ) {
                                                            return (
                                                            <tr key={shiftIdx}>
                                                                <td>
                                                                    {this.tConvert(shift[0])} 
                                                                        <br></br> 
                                                                    {this.tConvert(shift[1])}
                                                                </td>
                                                                <td className="employee-name">{shift[2]}</td>
                                                            </tr>
                                                            
                                                            )}, this)}
                                                        </tbody>
                                                    </Table>
                                                </td>
                                            )
                                        } else {
                                            return (
                                                false
                                            )
                                        }
                                    }, this)}
                                </tr>
                                </tbody>
                            </Table>
                            <div>
                                <hr></hr>
                                    <Table className="thick-border">
                                        <thead>
                                            <tr>
                                            <th className="thick-border">Employee Name</th>
                                            <th className="thick-border">Hours Worked</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {Object.keys(this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1][this.state.date.getDate()][7]).map(function(employee, employeeIdx, ) {
                                                return (
                                                <tr key={employeeIdx}>
                                                    <td className="thick-border">{employee}</td>
                                                    <td className="thick-border">{this.state.scheduleData[this.state.currentDispName][this.state.date.getFullYear()][this.state.date.getMonth()+1][this.state.date.getDate()][7][employee] + " hours"}</td>
                                                </tr>
    )}, this)}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            : 
                            <div>
                                No schedule found.
                            </div>
                        } 
                    </div>

                    
                </div>
            </div>
        )
    };

    
    render () {
        return (
            <div className='inventory-page'>
                {this.state.loaded ? this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}
  
export default SchedulePage;