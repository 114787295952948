import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import app from '../../firebase/firebase.utils';
import { AuthContext } from "../../Auth";
import { Button, Form } from 'react-bootstrap';
import Logo from '../../logos/logo_v3.jpg';

import './login-page.styles.css';

const LoginPage = ({ history }) => {
    const handleSubmit = useCallback(
      async event => {
        event.preventDefault();
        const { email, password } = event.target.elements;
        try {
          await app
            .auth()
            .signInWithEmailAndPassword(email.value, password.value);
          history.push("/");
        } catch (error) {
          alert(error);
        }
      },
      [history]
    );
  
    const { currentUser } = useContext(AuthContext);
  
    if (currentUser) {
      return <Redirect to="/" />;
    }
    return (
      <Form className="login-form" onSubmit={handleSubmit}>
        <div>
          <img className='login-logo' src={Logo} alt="Logo" />
        </div>
        
        <h4>Sign in with your email and password</h4>
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control name="email" type="email" placeholder="Email"></Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control name="password" type="password" placeholder="Password"></Form.Control>          
        </Form.Group>
        <Button className="login-button" variant='primary' type='submit'>Login</Button>
      </Form>
    );
};
  
export default withRouter(LoginPage);