import React, { Component } from 'react';
import './inventory-menu.styles.css';
import Header from '../../header/header.component';
import { Link } from "react-router-dom";
import { AuthContext } from '../../../Auth';

class InventoryPage extends Component {
    static contextType = AuthContext;

    constructor() {
        super();

        this.state = {
        }
    };

    render () {
        return (
            <div className='inventory-page'> 
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container inventory-page-container'>
                    <h4>Inventory Menu</h4>
                    <Link className='inventory-page-item' to={{pathname: "/inventory/new", state: {displayName: this.props.location.state.displayName}}}>New Inventory Order</Link>
                    <Link className='inventory-page-item' to={{pathname: "/inventory/past", state: {displayName: this.props.location.state.displayName}}}>Past Inventory Orders</Link>
                </div>
            </div>
        )
    };
}
  
export default InventoryPage;