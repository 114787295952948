import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';

const app = firebase.initializeApp({
  apiKey: "AIzaSyCWHkKgVdK-QV9qrCf903nzlKdKP9h7KYc",
  authDomain: "basra-corp.firebaseapp.com",
  databaseURL: "https://basra-corp.firebaseio.com",
  projectId: "basra-corp",
  storageBucket: "basra-corp.appspot.com",
  messagingSenderId: "56128276979",
  appId: "1:56128276979:web:4d1085fd678175c51e6a86"
});

export default app;