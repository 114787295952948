import React, { Component } from 'react';
import './schedule-page.styles.css';
import Header from '../header/header.component';
import app from '../../firebase/firebase.utils';
import {Button, Form, Spinner} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import LoadingPage from '../loading-page/loading-page.component';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

class SchedulePage extends Component {

    constructor() {
        super();
        var currentDate = new Date();
        var day = currentDate.getDay(),
        diff = currentDate.getDate() - day;
        currentDate = new Date(currentDate.setDate(diff))
        this.state = {
            date: currentDate,
            schedule: {},
            dispName: [],
            currentDispName: 'Ayr Fuel Stop',
            employees: [],
            currentEmployee: "none",
            startTime: "09:00",
            endTime: "17:00"
        }
    };

    async componentDidMount() {
        var dispName = [];
        if (this.props.location.state.displayName === 'Admin') {
            this.setState({
                isAdmin: true,
            })
            var firstUser = null;
            await app.database().ref('users').once('value', snapshot => {
                if (snapshot.val() !== null) {
                    var users = Object.keys(snapshot.val());
                    for (var i = 0; i < users.length; i++) {
                        if (snapshot.val()[users[i]].name !== 'Admin') {
                            if (i === 1) {
                                firstUser = snapshot.val()[users[i]].name;
                                this.setState({
                                    currentDispName: firstUser,
                                    dispName: [firstUser]
                                })
                            } else {
                                this.state.dispName.push(snapshot.val()[users[i]]['name'])
                            }
                            
                            dispName.push(snapshot.val()[users[i]]['name'])
                        }
                        
                    }
                }
            });
        } else {
            this.setState({
                currentDispName: this.props.location.state.displayName,
                dispName: [this.props.location.state.displayName]
            })
            dispName = [this.props.location.state.displayName]
        }

        await app.database().ref('schedule/').once('value', snapshot => {
            for (var i = 0; i < dispName.length; i++) {
                if (this.props.location.state.date !== undefined) {
                    const schedule = this.state.schedule;
                    schedule[this.props.location.state.currentDispName] = {
                        scheduleData: this.props.location.state.schedule.splice(0, 7),
                    };
                    this.setState(schedule);
                    this.setState({
                        currentDispName: this.props.location.state.currentDispName,
                        date: this.props.location.state.date
                    })

                } else {
                    if (this.state.schedule[dispName[i]] === undefined) {
                        const schedule = this.state.schedule;
                        schedule[dispName[i]] = {
                            scheduleData: [['Sunday', []], ['Monday', []],['Tuesday', []],['Wednesday', []],['Thursday', []],['Friday', []],['Saturday', []]]
                        };
                        this.setState(schedule);
                    }
                }
                if (this.state.employees[dispName[i]] === undefined) {
                    const employees = this.state.employees;
                    employees[dispName[i]] = [];
                    this.setState(employees);
                }
                if (snapshot.val() !== null) {
                    if (snapshot.val()[dispName[i]] !== undefined) {
                        if (snapshot.val()[dispName[i]]['employee'] !== undefined) {
                            var keys = Object.keys(snapshot.val()[dispName[i]]['employee']);
                            for (var j = 0; j < keys.length; j++) {
                                const employees = this.state.employees;
                                employees[dispName[i]].push(snapshot.val()[dispName[i]]['employee'][keys[j]]['name']);
                                this.setState(employees);
                            }
                            this.setState({
                                currentEmployee: this.state.employees[this.state.currentDispName][0]
                            })
                        }
                        if ((snapshot.val()[dispName[i]]['scheduleShift'] !== undefined) && (this.props.location.state.date === undefined)){
                            keys = Object.keys(snapshot.val()[dispName[i]]['scheduleShift']);
                            for (j = 0; j < keys.length; j++) {
                                for (var k = 0; k < this.state.schedule[dispName[i]]['scheduleData'].length; k++) {
                                    const schedule = this.state.schedule;
                                    schedule[dispName[i]]['scheduleData'][k][1].push([
                                        snapshot.val()[dispName[i]]['scheduleShift'][keys[j]]['Start'], 
                                        snapshot.val()[dispName[i]]['scheduleShift'][keys[j]]['End'],
                                        this.state.currentEmployee
                                    ]);
                                    this.setState(schedule);
                                }
                            }
                        }
                        
                    }
                }
            }
        });

        this.setState({ 
            loaded: true,
        });
    }

    scheduleDataChanged(idx, dayIdx, event) {
        const schedule = this.state.schedule;
        schedule[this.state.currentDispName]['scheduleData'][dayIdx][idx] = event.target.value;
        this.setState(schedule);
    }

    addShift(dayIdx, event) {
        const schedule = this.state.schedule;
        schedule[this.state.currentDispName]['scheduleData'][dayIdx][1].push([
            this.state.startTime, 
            this.state.endTime,
            this.state.currentEmployee,
        ])
        this.setState(schedule);
        this.setState({
            startTime: "09:00",
            endTime: "17:00"
        })
    }

    deleteShift(dayIdx, shiftIdx, event) {
        const schedule = this.state.schedule;
        schedule[this.state.currentDispName]['scheduleData'][dayIdx][1].splice(shiftIdx, 1);
        this.setState(schedule);
    }

    changeEmployee(dayIdx, shiftIdx, event) {
        const schedule = this.state.schedule;
        schedule[this.state.currentDispName]['scheduleData'][dayIdx][1][shiftIdx][2] = event.target.value;
        this.setState(schedule);
    }

    changeStartTime(event) {
        this.setState({
            startTime: event.target.value
        })
    }

    changeEndTime(event) {
        this.setState({
            endTime: event.target.value
        })
    }

    async saveChanges(event) {
        var shiftSummary = {}
        for (var i = 0; i < this.state.schedule[this.state.currentDispName]['scheduleData'].length; i++){
            for (var j = 0; j < this.state.schedule[this.state.currentDispName]['scheduleData'][i][1].length; j++) {
                var startTime = this.state.schedule[this.state.currentDispName]['scheduleData'][i][1][j][0];
                var endTime = this.state.schedule[this.state.currentDispName]['scheduleData'][i][1][j][1];

                var s = startTime.split(':');
                var e = endTime.split(':');

                var end = parseInt(e[0])* 60+ parseInt(e[1]);
                var start = parseInt(s[0])*60 + parseInt(s[1]);

                var elapsedMs = end - start;

                if (elapsedMs < 0) {
                    elapsedMs = (60*24) + elapsedMs
                }

                elapsedMs = elapsedMs/60;

                if (elapsedMs > 5.5) {
                    elapsedMs = elapsedMs - 0.5;
                }
                    
                if (shiftSummary[this.state.schedule[this.state.currentDispName]['scheduleData'][i][1][j][2]] === undefined) {
                    shiftSummary[this.state.schedule[this.state.currentDispName]['scheduleData'][i][1][j][2]] = elapsedMs
                } else {
                    shiftSummary[this.state.schedule[this.state.currentDispName]['scheduleData'][i][1][j][2]] = shiftSummary[this.state.schedule[this.state.currentDispName]['scheduleData'][i][1][j][2]] + elapsedMs
                }
            }
        }

        this.state.schedule[this.state.currentDispName]['scheduleData'].push(shiftSummary);

        await app.database().ref('schedule/' + this.state.currentDispName + '/pastSchedule/' + this.state.date.getFullYear() + "/" + (this.state.date.getMonth()+1) + "/" + this.state.date.getDate()).set(this.state.schedule[this.state.currentDispName]['scheduleData']);

        this.state.schedule[this.state.currentDispName]['scheduleData'].splice(this.state.schedule[this.state.currentDispName]['scheduleData'].length-1,1)
    }

    handleDateChange = (date) => {
        this.setState({
            date: date
        });
    }

    isWeekday = date => {
        const day = date.getDay();
        return day === 0;
    }

    handleUserChange = (user) => {
        this.setState({
            currentDispName: user
        });
    }

    changeTime(dayIdx, shiftIdx, pos, event) {
        const schedule = this.state.schedule;
        schedule[this.state.currentDispName]['scheduleData'][dayIdx][1][shiftIdx][pos] = event.target.value;
        this.setState(schedule);
    }

    content () {
        return (
            <div className='inventory-page'> 
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container container-with-footer'>
                    <h4>Create New Schedule</h4>
                    {this.state.isAdmin? 
                    <div>
                    
                    
 <Form.Group>
     <Form.Label>Schedule for:  </Form.Label>
         <Form.Control as="select" onChange={this.handleUserChange.bind(this)}>
         {this.state.dispName.map(function(user, userIdx, ) {
             return (
                <option key={userIdx}>{user}</option>
         )}, this)}
     </Form.Control>    
 </Form.Group>
 <hr></hr>
 </div> : false
    }
 <div className="week-selector">
                        Select Week:&nbsp;
                        <DatePicker filterDate={this.isWeekday.bind(this)} selected={this.state.date} onChange={this.handleDateChange.bind(this)} placeholderText="Select a week"/>
                    </div>       <hr></hr>  
                    <div>
                        {this.state.schedule[this.state.currentDispName]['scheduleData'].map(function(day, dayIdx, ) {
                            return (
                            <div key={dayIdx}>
                                <h6>{day[0]}</h6>
                                {day[1].map(function(shift, shiftIdx, ) {
                                    return (
                                        <div key={shiftIdx} className="timeslot">
                                        <span>Start:&nbsp;</span>
                                        <TextField
                                            className="child"
                                            
                                            id="time"
                                            type="time"
                                            defaultValue={shift[0]}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={
                                                this.changeTime.bind(this, dayIdx, shiftIdx, 0)
                                            }
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                        />
                                        <span>&nbsp;End:&nbsp;</span>
                                        <TextField
                                            className="child"
                                            
                                            id="time"
                                            type="time"
                                            defaultValue={shift[1]}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={
                                                this.changeTime.bind(this, dayIdx, shiftIdx, 0)
                                            }
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                        />
                                        <span>&nbsp;</span>
                                            <Form.Control defaultValue={this.state.schedule[this.state.currentDispName]['scheduleData'][dayIdx][1][shiftIdx][2]} className="child" onChange={this.changeEmployee.bind(this, dayIdx, shiftIdx)}  as="select">
                                                {this.state.employees[this.state.currentDispName].map(function(employee, employeeIdx, ) {
                                                    return (
                                                    <option key={employee}>{employee}</option>
                                                )})}
                                            </Form.Control>
                                            <Button className="child" variant="danger" size="sm" onClick={this.deleteShift.bind(this, dayIdx, shiftIdx)}>Delete</Button>
                                        </div>
                                    )
                                }, this)}
                                <div className="timeslot">
                                

                                        <span>Start:&nbsp;</span>
                                        <TextField
                                            className="childInput"
                                            defaultValue="09:00"
                                            id="time"
                                            type="time"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={
                                                this.changeStartTime.bind(this)
                                            }
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                        />
                                        <span>&nbsp;End:&nbsp;</span>
                                        <TextField
                                            className="childInput"
                                            defaultValue="17:00"
                                            id="time"
                                            type="time"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={
                                                this.changeEndTime.bind(this)
                                            }
                                            inputProps={{
                                            step: 300, // 5 min
                                            }}
                                        />
                                        <span>&nbsp;</span>
                                <Button className="childInput" size="sm" onClick={this.addShift.bind(this, dayIdx)}>Add Shift</Button>
                                </div>
                                <hr></hr>
                            </div>
                            )
                        }, this)}
                    </div>

                    <div className='sticky-footer'>
                        {this.state.verificationSpinner? 
                            <Button className='float-right' variant="warning">Uploading...<Spinner animation="border" role="status" size="sm"></Spinner></Button> 
                            : <Button className="save-button" onClick={this.saveChanges.bind(this)}>Save</Button>}        
                        
                    </div>
                
                </div>
            </div>
        )
    };

    render () {
        return (
            <div className='inventory-page'>
                {this.state.loaded ? this.content() : 
                <LoadingPage displayName={this.props.location.state.displayName}></LoadingPage>
                }
            </div>
    )};
}
  
export default SchedulePage;