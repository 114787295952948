import React, { Component } from 'react';
import './schedule-menu.styles.css';
import Header from '../../header/header.component';
import { Link } from "react-router-dom";

class ScheduleMenu extends Component {

    constructor() {
        super();

        this.state = {
        }
    };

    render () {
        return (
            <div className='inventory-page'> 
                <Header displayName={this.props.location.state.displayName}></Header>
                <div className='container inventory-page-container'>
                    <h4>Schedule Menu</h4>
                    <Link className='inventory-page-item' to={{pathname: "/schedule/new", state: {displayName: this.props.location.state.displayName}}}>New Schedule</Link>
                    <Link className='inventory-page-item' to={{pathname: "/schedule/past", state: {displayName: this.props.location.state.displayName}}}>Past Schedule</Link>
                    <Link className='inventory-page-item' to={{pathname: "/schedule/employees", state: {displayName: this.props.location.state.displayName}}}>Manage Employees</Link>
                </div>
            </div>
        )
    };
}
  
export default ScheduleMenu;